/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import Axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import InformationField from 'components/BloomForms/InformationField';
import SelectButton from 'components/BloomForms/SelectButton';
import {
  FormContainer,
  FormContainerTitle
} from 'components/BloomForms/WrapperContainer.style';
import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import CopyClipBoardIcon from 'images/icons/copyClipboards/copyClipboard.svg';
import GoogleSearchIcon from 'images/icons/searches/googleSearchIcon.svg';
import searchInputIconWhite from 'images/icons/searches/searchInputIconWhite.svg';
import googleSearch from 'services/googleSearch';
import copyClipBoard from 'utils/copyClipboard';
import Environment from 'utils/environments';
import { getFilterOptions } from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';
import { ShortenStringTooltip } from 'utils/shortenString';

import {
  DescriptionContainer,
  EmptySku,
  FieldLine,
  RestaurantDescription,
  TableWrapper,
  TopFormContainerWrapper,
  WithAutoCompleteWrapper
} from './index.style';

const BG_COLOR_EQUALS = '#B0D7E6';
const environments = Environment(process.env.REACT_APP_ENV);

function ComparationCard({
  card = {},
  setComparationCurrentData,
  similarSkuComparation = false,
  setSimilarSkuCurrentData = () => {},
  selectProviderMode = false,
  setSelectedSkus = () => {},
  setTotalSelectedSKU = () => {},
  setSimilarSku = () => {},
  setValuesOnStateEqualsFields = () => {},
  leftValuesComparation = null,
  hasEqualsFields,
  similarOptions,
  setComparationEan,
  comparationEan,
  searchByEan,
  setSearchByEan,
  skuDialogOpen,
  comparationCardFilterValue,
  setComparationCardFilterValue,
  setSimilarSkusOpen,
  setEnableOpenSkuModal,
  isCatalogedMode,
}) {
  const { id: paramsID } = useParams();
  const rows = [1, 2];
  const [internalCard, setInternalCard] = useState(card);
  const [selectAllProviders, setSelectAllProviders] = useState(true);
  const [providersUniques, setProvidersUniques] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [debounceTimers, setDebounceTimers] = useState([]);

  const [copied, setCopied] = useState(false);

  const useStyles = makeStyles(() => ({
    headerCell: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      fontSize: '9px',
      margin: '0 10px',
      whiteSpace: 'nowrap',
      background: '#313347',
      color: '#FFF',
      padding: '6px 12px'
    },
    bodyCell: {
      fontFamily: 'Lato',
      fontSize: '12px',
      whiteSpace: 'nowrap',
      padding: '6px 12px'
    },
    descriptionSku: {
      display: 'inline-grid',
      gridAutoFlow: 'column'
    },
    copyText: {
      cursor: 'pointer'
    },
    textSku: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      border: 'none',
      textOverflow: 'ellipsis'
    }
  }));

  const classes = useStyles();
  const {
    id: provideCode = '',
    description = '',
    ean,
    restaurants = [],
    brands: {
      description: brandDescription = '',
      producers: producersDescription
    } = {},
    providers = [],
    quantity = '',
    unitsMeasurements: { abbreviation = '' } = {},
    specification,
    created_at,
    products: [{ priceUc: consumptionUnitPrice = 0 } = {}] = [],
    products: [
      {
        products: {
          consumptionUnitsPrimary: {
            abbreviation: consumptionUnitAbbreviation = ''
          } = {}
        } = {}
      } = {}
    ] = []
  } = {
    ...internalCard,
    brands: internalCard?.brands || {},
    specification: internalCard?.specification || '',
    ean: internalCard?.ean || '',
    unitsMeasurements: internalCard?.unitsMeasurements || {}
  };

  const [searchAutoComplete, setSearchAutoComplete] = useState([
    [
      {
        label: 'skus',
        filterName: 'skus',
        placeHolder: 'Buscar...',
        urlPath: `${environments.catalog}/skus/platform/similar`,
        key: 'skusComparation',
        optionReference: 'description',
        options: []
      }
    ],
    []
  ]);

  const [firstRestaurant] = restaurants;
  const restaurantDescription = firstRestaurant?.description;
  const inputRef = useRef(null);

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    setEnableOpenSkuModal(true);
    getFilterOptions(
      urlPath,
      label,
      key,
      searchAutoComplete,
      setSearchAutoComplete,
      optionReference,
      paramsID,
      { autoComplete: true },
      null,
      null,
      null,
      10000
    );
  };

  const handleSearchOptions = (filterName, value, filter) => {
    if (!filter) {
      setInternalCard(card);
      setSimilarSkuCurrentData(null);
      setValuesOnStateEqualsFields({
        left: {
          products: []
        },
        right: {
          products: []
        }
      });
      return;
    }

    const currentParam = filter?.content?.ean ? `ean=${filter?.content?.ean}` : `descriptionSku=${filter?.content?.description}`

    Axios
      .get(
        `${environments.catalog}/skus/platform/similar?${currentParam}`
      )
      .then((response) => {
        const content = response.data?.content;
        
        setInternalCard(content?.length ? content[0] : '');
        setSimilarSkuCurrentData(content?.length ? content[0] : '');
        setValuesOnStateEqualsFields({
          left: leftValuesComparation,
          right: content?.length ? content[0] : ''
        });
      })
      .catch((error) => {
        console.log(error?.response?.status);
      });
  };

  const handleSelectProviderItem = (provider) => {
    providersUniques.forEach((providerUnique) => {
      if (providerUnique.id !== provider.id) return;
      providerUnique.isSelected = !provider.isSelected;
    });

    setProvidersUniques(providersUniques);

    const filteredUniques = providersUniques.filter((unique) => {
      if (unique.isSelected && !unique.isDisableSku) {
        return unique;
      }
    });

    setSelectedSkus(filteredUniques);
    setTotalSelectedSKU(
      providersUniques.filter((provider) => !provider.isDisableSku).length
    );
    setProvidersUniques([...providersUniques]);
  };

  const handleSelectProviderItemSimilar = (provider) => {
    providersUniques.forEach((providerUnique) => {
      if (providerUnique.id !== provider.id) {
        providerUnique.isSelected = false;
        return;
      }
      providerUnique.isSelected = !provider.isSelected;
    });
    setProvidersUniques([...providersUniques]);
    const filteredUniques = providersUniques.filter((unique) => {
      if (unique.isSelected) {
        return unique;
      }
    });
    setSimilarSku(filteredUniques);
  };

  const handleSelectAllProviders = (status) => {
    const newProvidersUniques = providersUniques.map((provider) => {
      provider.isSelected = status;
      return provider;
    });
    setProvidersUniques(newProvidersUniques);
    setSelectAllProviders(status);
  };

  const handleValue = (value) => {
    setComparationEan(value);
    setSearchByEan(!searchByEan);
  };

  const searchSkuInEAN = () => {
    const sanitizeInputValue = inputValue ? inputValue?.trim() : '';
    if (!sanitizeInputValue.length) return;

    handleValue(sanitizeInputValue);
  };

  const handleInputValue = (value) => {
    setInputValue(value);
    setComparationCurrentData({
      ...card,
      ean: value
    });

    if (debounceTimers.length) {
      debounceTimers.forEach((item) => {
        clearTimeout(item);
      });
    }

    const newDebouceTimer = setTimeout(() => {
      handleValue(value);
    }, 800);

    setDebounceTimers([...debounceTimers, newDebouceTimer]);
  };

  useEffect(() => {
    setSearchByEan(!ean);
  }, [ean]);

  useEffect(() => {
    if (!inputRef.current) return;

    const currentInput = inputRef.current.getElementsByTagName('input');

    if (!currentInput.length) return;

    !skuDialogOpen && currentInput[0].focus();
  }, [inputRef, card, skuDialogOpen]);

  useEffect(() => {
    setInternalCard(card);

    !similarSkuComparation && setInputValue(card?.ean || '');

    if (similarSkuComparation) {
      setSimilarSkuCurrentData(card);
      setValuesOnStateEqualsFields({
        left: leftValuesComparation,
        right: card
      });

      setComparationCardFilterValue(
        similarOptions?.find((item) => item.id === card?.id) || {}
      );
    }
  }, [card]);

  useEffect(() => {
    const uniques = [];

    providers
      .filter((provider) =>
        similarSkuComparation
          ? provider.statusCataloguing === 'APPROVED'
          : provider.statusCataloguing !== 'REFUSED' &&
            provider.statusCataloguing !== 'APPROVED'
      )
      .forEach((provider) => {
        const check = uniques.filter(
          (arr) => arr.codeProvider === provider.codeProvider && arr?.providerId === provider?.providerId
        );

        if (!check.length) {
          provider.isSelected = !similarSkuComparation;
          uniques.push(provider);
        }
      });

    setProvidersUniques(uniques);
    setSelectedSkus(uniques);
    setTotalSelectedSKU(uniques.length);

    return () => {
      setProvidersUniques([]);
    };
  }, [card, internalCard]);

  return (
    <FormContainer width="50%">
      <FormContainerTitle toUppercase>
        {!similarSkuComparation ? (
          <>
            <RestaurantDescription>
              {restaurantDescription}
            </RestaurantDescription>

            <TopFormContainerWrapper>
              ENVIOU ESSE SKU EM {moment(created_at).format('DD-MM-YYYY')}
            </TopFormContainerWrapper>
          </>
        ) : (
          <WithAutoCompleteWrapper>
            <div>SKU SIMILAR CADASTRADO NA BASE</div>

            <div className="dFlex">
              <AutoCompleteField
                updateDefaultValue={comparationCardFilterValue}
                notMultipleDefaultValue
                urlPath={searchAutoComplete[0][0].urlPath}
                optionReference={searchAutoComplete[0][0].optionReference}
                placeHolder={searchAutoComplete[0][0].placeHolder}
                filterName={searchAutoComplete[0][0].filterName}
                options={searchAutoComplete[0][0].options}
                keyField={searchAutoComplete[0][0].key}
                getFilterhandle={getFilterhandle}
                size="large"
                label={searchAutoComplete[0][0].label}
                inputSizes={{
                  small: 110,
                  medium: 168,
                  large: 320
                }}
                color={{
                  label: 'white',
                  input: 'white',
                  content: 'white'
                }}
                inputHeight="0.5em"
                marginTop="-5px"
                marginRight="0"
                nolabel
                handleFilter={handleSearchOptions}
              />

              <img
                src={searchInputIconWhite}
                id="searchIcon"
                alt="searchIcon"
                onClick={() => setSimilarSkusOpen(true)}
              />
            </div>
          </WithAutoCompleteWrapper>
        )}
      </FormContainerTitle>

      {!provideCode && (
        <EmptySku>
          <span>Nenhum SKU relacionado </span>
        </EmptySku>
      )}

      {provideCode && (
        <>
          <DescriptionContainer>
            {inputValue ? (
              <>
                <a
                  onClick={() => {
                    googleSearch(inputValue);
                  }}
                  style={{
                    position: 'absolute',
                    top: '8.55vh',
                    left: '40px',
                    cursor: 'pointer',
                    zIndex: 9
                  }}
                >
                  <img
                    style={{ marginRight: '4px' }}
                    src={GoogleSearchIcon}
                    alt="googleSearchIcon"
                  />
                </a>

                <Tooltip
                  placement="right"
                  title={
                    <p style={{ padding: '4px 6px' }}>
                      {copied ? 'Copiado' : 'Copiar'}
                    </p>
                  }
                >
                  <a
                    onMouseLeave={() => {
                      setTimeout(() => {
                        setCopied(false);
                      }, 1000);
                    }}
                    onClick={() => {
                      copyClipBoard(inputValue, '', '');
                      setCopied(true);
                    }}
                    style={{
                      position: 'absolute',
                      top: '8.55vh',
                      left: '57px',
                      cursor: 'pointer',
                      zIndex: 9
                    }}
                  >
                    <img
                      style={{ marginRight: '4px' }}
                      src={CopyClipBoardIcon}
                      alt="CopyClipboard"
                    />
                  </a>
                </Tooltip>
              </>
            ) : null}

            <FieldLine gridTemplate="1fr">
              <InformationField
                label="DESCRIÇÃO SKU"
                content={description}
                color={hasEqualsFields.description ? BG_COLOR_EQUALS : null}
              />
            </FieldLine>

            <FieldLine gridTemplate="1fr 1fr 1fr 1fr 1fr">
              <div className="dFlex flex-md-column">
                <div className="dFlex flexColumn pRelative">
                  <label
                    className="fontSizeDefault pAbsolute bold"
                    style={{ top: 0, zIndex: 999 }}
                  >
                    EAN
                  </label>

                  {!similarSkuComparation ? (
                    <div className="dFlex">
                      <img
                        className="cursorPointer"
                        style={{ marginRight: '7px', marginTop: '13px' }}
                        src={GoogleSearchIcon}
                        alt="googleSearchIcon"
                        onClick={() => {
                          searchSkuInEAN();
                        }}
                      />

                      <TextField
                        id={`${inputValue}`}
                        ref={inputRef}
                        style={{
                          marginTop: '10px',
                          background: hasEqualsFields.ean
                            ? BG_COLOR_EQUALS
                            : '#FFF'
                        }}
                        name="ean"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={inputValue}
                        className="textFieldSmall"
                        placeholder="Digite aqui..."
                        onFocus={(e) => e.target.select()}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            searchSkuInEAN();
                          }
                        }}
                        inputProps={{ style: { paddingRight: 0 } }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(e) => {
                          handleInputValue(e.target.value);
                        }}
                        disabled={false}
                      />
                    </div>
                  ) : (
                    <TextField
                      style={{
                        marginTop: '9px',
                        background: hasEqualsFields.ean
                          ? BG_COLOR_EQUALS
                          : '#FFF'
                      }}
                      name="ean"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={comparationEan}
                      className="textFieldSmall disabledTextWithoutStyle"
                      disabled
                    />
                  )}
                </div>
              </div>

              <InformationField
                label="QUANTIDADE"
                content={`${numberToReal(quantity)} ${abbreviation}`}
                align="end"
                color={hasEqualsFields.quantity ? BG_COLOR_EQUALS : null}
              />

              {restaurants?.length ? (
                <InformationField
                  label="MÉDIO R$"
                  align="end"
                  content={numberToReal(restaurants[0]?.purchaseAverage)}
                  color={hasEqualsFields.averagePrice ? BG_COLOR_EQUALS : null}
                />
              ) : null}

              {restaurants?.length ? (
                <InformationField
                  label="ÚLTIMO R$"
                  align="end"
                  content={numberToReal(restaurants[0]?.purchaseLast)}
                  color={hasEqualsFields.lastPrice ? BG_COLOR_EQUALS : null}
                />
              ) : null}

              {consumptionUnitPrice && consumptionUnitAbbreviation ? (
                <InformationField
                  label="UN. CONS. R$"
                  align="end"
                  content={`${numberToReal(
                    consumptionUnitPrice
                  )} ${consumptionUnitAbbreviation}`}
                  color={
                    hasEqualsFields.consumptionUnit ? BG_COLOR_EQUALS : null
                  }
                />
              ) : null}
            </FieldLine>
          </DescriptionContainer>

          <DescriptionContainer withMargin>
            <FieldLine gridTemplate="2.4fr 4fr">
              <div className="dFlex">
                <InformationField
                  label="MARCA"
                  content={
                    <ShortenStringTooltip
                      string={brandDescription || '-'}
                      length={20}
                    />
                  }
                />
              </div>

              <InformationField
                label="FABRICANTE"
                content={
                  <ShortenStringTooltip
                    string={producersDescription?.description || '-'}
                    length={20}
                  />
                }
              />
            </FieldLine>
          </DescriptionContainer>

          {specification && (
            <DescriptionContainer withMargin>
              <FieldLine gridTemplate="1fr">
                <InformationField
                  label="ESPECIFICAÇÕES"
                  content={specification}
                />
              </FieldLine>
            </DescriptionContainer>
          )}

          {providersUniques.length ? (
            <TableWrapper>
              <Table size="small">
                <TableHead>
                  <TableRow classes={{ head: classes.headerRow }}>
                    <TableCell
                      style={{ position: 'sticky', top: 0 }} 
                      width="40%" 
                      className={classes.headerCell}
                    >
                      {selectProviderMode && (
                        <SelectButton
                          isSelected={selectAllProviders}
                          setIsSelected={(status) => {
                            handleSelectAllProviders(status);
                          }}
                          selectAllIcon
                        />
                      )}

                      <span
                        style={{
                          marginLeft: selectProviderMode ? '4px' : '20px'
                        }}
                      >
                        CÓDIGO/FORNECEDOR
                      </span>
                    </TableCell>

                    <TableCell
                      style={{ position: 'sticky', top: 0 }}
                      width={similarSkuComparation ? '40%' : '35'}
                      className={classes.headerCell}
                    >
                      DESCRIÇÃO FORNECEDOR/R$ EMB.
                    </TableCell>

                    {!similarSkuComparation && (
                      <TableCell
                        style={{ position: 'sticky', top: 0 }}
                        width="10%"
                        className={`${classes.headerCell} textAlignRight`}
                      >
                        QTDE
                      </TableCell>
                    )}

                    <TableCell
                      style={{ position: 'sticky', top: 0 }}
                      width={similarSkuComparation ? '20%' : '15%'}
                      className={classes.headerCell}
                    >
                      EMB./R$ UNIT.
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {providersUniques.map((sku, index) => (
                    <>
                      {rows.map((row) => (
                        <TableRow hover={false}>
                          <TableCell className={classes.bodyCell}>
                            {row === 1 && selectProviderMode && (
                              <SelectButton
                                isSelected={sku.isSelected}
                                setIsSelected={() =>
                                  handleSelectProviderItem(sku)
                                }
                                disable={sku.isDisableSku || isCatalogedMode}
                                id={`comparationSelecteItemProvider-${index}`}
                                className="comparationSelecteItemProvider"
                              />
                            )}

                            {row === 1 && !selectProviderMode && (
                              <SelectButton
                                isSelected={sku.isSelected}
                                setIsSelected={() =>
                                  handleSelectProviderItemSimilar(sku)
                                }
                                radioButton
                                disable={isCatalogedMode}
                                id={`comparationSelecteItemSimilar-${index}`}
                                className="comparationSelecteItemSimilar"
                              />
                            )}

                            <span style={{ marginLeft: '4px' }}>
                              {row === 1 && (
                                <ShortenStringTooltip
                                  string={
                                    sku.codeProvider ? sku.codeProvider : ''
                                  }
                                  length={12}
                                />
                              )}

                              {row === 2 && (
                                <ShortenStringTooltip
                                  string={sku.name ? sku.name : ''}
                                  length={32}
                                />
                              )}
                            </span>
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {row === 1 && (
                              <div className={`${classes.descriptionSku}`}>
                                {sku?.descriptionSku &&
                                sku?.descriptionSku !== null ? (
                                  <>
                                    <a
                                      onClick={() => {
                                        googleSearch(sku.descriptionSku);
                                      }}
                                    >
                                      <img
                                        style={{
                                          marginRight: '4px',
                                          marginBottom: '4px'
                                        }}
                                        src={GoogleSearchIcon}
                                        alt="googleSearchIcon"
                                      />
                                    </a>

                                    <Tooltip
                                      placement="bottom"
                                      title={copied ? 'Copiado' : 'Copiar'}
                                    >
                                      <a
                                        onMouseLeave={() => {
                                          setTimeout(() => {
                                            setCopied(false);
                                          }, 1000);
                                        }}
                                        onClick={() => {
                                          copyClipBoard(
                                            'sku-description',
                                            index,
                                            ''
                                          );
                                          setCopied(true);
                                        }}
                                        className={classes.copyText}
                                      >
                                        <img
                                          style={{
                                            marginRight: '5px',
                                            marginBottom: '3px'
                                          }}
                                          src={CopyClipBoardIcon}
                                          alt="CopyClipboard"
                                        />
                                      </a>
                                    </Tooltip>
                                  </>
                                ) : null}

                                {sku?.descriptionSku &&
                                  sku?.descriptionSku?.length > 17 && (
                                    <Tooltip
                                      placement="bottom"
                                      title={sku?.descriptionSku}
                                    >
                                      <div
                                        className={classes.textSku}
                                        id={`sku-description-${index}`}
                                      >
                                        {sku?.descriptionSku}
                                      </div>
                                    </Tooltip>
                                  )}

                                {sku?.descriptionSku &&
                                  sku?.descriptionSku?.length < 17 && (
                                    <div
                                      className={classes.textSku}
                                      id={`sku-description-${index}`}
                                    >
                                      {sku?.descriptionSku}
                                    </div>
                                  )}
                              </div>
                            )}

                            {row === 2 &&
                              `R$ ${numberToReal(sku.purchaseLast, 3)}`}
                          </TableCell>

                          {!similarSkuComparation && (
                            <TableCell className={classes.bodyCell}>
                              {row === 1 && (
                                <CurrencyTextField
                                  variant="standard"
                                  size="small"
                                  currencySymbol=""
                                  minimumValue="0"
                                  outputFormat="string"
                                  decimalCharacter=","
                                  digitGroupSeparator="."
                                  decimalPlaces={3}
                                  decimalPlacesShownOnFocus={3}
                                  name="packageQuantity"
                                  fullWidth
                                  value={sku?.quantity}
                                  className="textFieldSmall"
                                  placeholder="Digite aqui..."
                                  onFocus={(e) => e.target.select()}
                                  type="text"
                                  inputProps={{
                                    style: {
                                      textAlign: 'right',
                                      marginBottom: 0,
                                      paddingBottom: 2
                                    }
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  onBlur={(e) => {
                                    const formattedValue = parseFloat(
                                      e.target.value?.replace(',', '.')
                                    );

                                    if (!formattedValue) {
                                      setProvidersUniques(
                                        providersUniques.map((item) => {
                                          if (item.id === sku.id) {
                                            return {
                                              ...item,
                                              quantity: 1
                                            };
                                          }

                                          return {
                                            ...item
                                          };
                                        })
                                      );

                                      if (sku?.isSelected) {
                                        setSelectedSkus(
                                          providersUniques.map((item) => {
                                            if (item.id === sku.id) {
                                              return {
                                                ...item,
                                                quantity: 1
                                              };
                                            }

                                            return {
                                              ...item
                                            };
                                          })
                                        );
                                      }
                                    } else {
                                      setProvidersUniques(
                                        providersUniques.map((item) => {
                                          if (item.id === sku.id) {
                                            return {
                                              ...item,
                                              quantity: formattedValue
                                            };
                                          }

                                          return {
                                            ...item
                                          };
                                        })
                                      );

                                      if (sku?.isSelected) {
                                        setSelectedSkus(
                                          providersUniques.map((item) => {
                                            if (item.id === sku.id) {
                                              return {
                                                ...item,
                                                quantity: formattedValue
                                              };
                                            }

                                            return {
                                              ...item
                                            };
                                          })
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                            </TableCell>
                          )}

                          <TableCell className={classes.bodyCell}>
                            {row === 1 &&
                              `E${sku.quantity ? sku.quantity : 0}x${
                                quantity || 0
                              }${abbreviation || ''}`}

                            {row === 2 &&
                              `R$ ${numberToReal(
                                sku.purchaseLast / sku.quantity,
                                3
                              )}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          ) : null}
        </>
      )}
    </FormContainer>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(ComparationCard);
