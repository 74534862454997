export function setTickets(data) {
    return {
        type: 'SET_TICKETS_DATA',
        data,
    };
}

export function createTickets(data) {
    return {
        type: 'CREATE_TICKETS_DATA',
        data,
    };
}