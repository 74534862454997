/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {  Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ItemsPerPage from 'components/ItemsPerPage';
import Localization, {
  LocalizationContext,
} from 'components/Localization';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import TicketsForm from 'pages/admin/tickets/TicketsForm';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as TicketsActions from 'store/actions/tickets';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import { getFilterOptions, handleFilterOptions } from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

function Tickets({ userInfo, setTickets, createTickets }) {
  const listStatus = [{description: "TO DO", id: "TO DO"}, {description: "WIP", id: "WIP"}, {description: "QA", id: "QA"}, {description: "DEPLOY", id: "DEPLOY"}, {description: "PROD", id: "PROD"}, {description: "N BUG", id: "N BUG"} ]
  const environments = Environment(process.env.REACT_APP_ENV);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 100,
    mainContent: [],
    numberOfElements: 0,
    loading: true,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    mainContent,
    numberOfElements,
    totalElements
  } = callSettings;
  const [ticketModal, setTicketModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [filtersChanged, setFiltersChanged] = useState([])

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'ID',
        filterName: 'jiraId',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Tema',
        filterName: 'type',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Descrição',
        filterName: 'description',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Cliente',
        filterName: 'client',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: "Status",
        filterName: "status",
        filterNameSecond: "status",
        placeHolder: "Selecione...",
        type: "autoComplete",
        key: "description",
        optionReference: "description",
        options: listStatus,
        size: "medium",
        searchByTermMode: true
      }
    ]
  ])
  const [userCreate] = useState(userInfo.user == "fabricioadmin" || userInfo.user == "marcioadmin")

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }


  const handleFilter = (param, value, _, paramSecond) => {
    const hasFilterWithFirstName = filtersChanged?.filter(el => el?.param === param)?.length
    const hasFilterWithSecondName = filtersChanged?.filter(el => el?.param === paramSecond)?.length

    let formattedFilters = hasFilterWithSecondName ? filtersChanged?.filter(el => el?.param !== paramSecond) : filtersChanged

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(el => el?.param !== paramSecond && el?.param !== param)
    }

    handleFilterOptions(param, value, formattedFilters, setFiltersChanged, null, false, '1')
  }

  const toggleTicketsModal = () => {
    setTicketModal(!ticketModal);

    if (ticketModal === false) {
      setUpdateList(false);
    }
  };

  const getMainContent = async (page, size) => {
    const params = filtersChanged.map(fc => fc.urlParam).join("&")
    const response = await axiosInstanceCatalog.get(
      `${environments.dashBoard}/tickets?${params}`
    );
    const tickets = await response.data;
    setCallSettings({
      ...callSettings,
      totalPages: tickets?.totalPages || 1,
      mainContent: tickets.content || [],
      numberOfElements: tickets?.numberOfElements,
      loading: false,
      totalElements: tickets.totalElements
    });
  }

  const addTicket = () => (
    <Tooltip title={<Localization text="Cadastrar novo ticket" />} aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          toggleTicketsModal();
          createTickets();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const { t } = useTranslation('translations');

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    filterFields?.forEach(fields => {
      fields?.forEach(({ urlPath, label, key, optionReference }) => {
        if (!urlPath) {
          return
        }

        getFilterOptions(urlPath, label, key, filterFields, setFilterFields, optionReference, getId, filtersChanged, 1)
      })
    })
  }, [getId, updateList])

  useEffect(() => {
    setCallSettings({ ...callSettings, page: 1 })
    getMainContent(page, size)
  }, [getId, updateList, filtersChanged]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  return (
    <Layout>
      <LocalizationContext.Provider value={{ nameSpace: 'tickets' }}>
        <TitleHead title={"Tickets"} />

        <GlobalStyle />

        <Container>
          <PageTitleAndFilter
            title={<Localization text={"Tickets"} />}
            activeFilterFilds={filterFields}
            handleFilter={handleFilter}
            additionalbutton={userCreate ? addTicket : null}
          />
          <table
            className="desktop"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="5%" className="subtitle">
                  ID
                </th>

                <th width="10%" className="subtitle">
                  TEMA
                </th>

                <th width="70%" className="subtitle">
                  DESCRIÇÃO
                </th>
                
                <th width="10%" className="subtitle">
                  RESTAURANTE
                </th>

                <th width="5%" className="subtitle">
                  STATUS
                </th>
        
              </tr>
            </thead>

            <tbody>
              {
                mainContent.length === 0 ? (
                  <tr>
                    <th
                      colSpan="18"
                      style={{ margin: '10px', textAlign: 'center' }}
                    >
                      Nenhum ticket encontrado
                    </th>
                  </tr>
                ) : null
              }

              {mainContent.map((ticket) => (
                <>
                  <tr>
                    {
                    userCreate ?
                    <td className={"description p12"}>
                      <span
                        className="cursorPointer lightBlue"
                        onClick={() => { 
                          setTickets(ticket);
                          toggleTicketsModal();
                        }}
                      >
                        {ticket?.jiraId}
                      </span>
                    </td>
                    :
                    <td className={"description p12"}>
                      {ticket?.jiraId}
                    </td>}

                    <td className="type">
                      {ValidationLength(ticket?.type?.toUpperCase(), 13)}
                    </td>

                    <td className="description">
                      {ValidationLength(ticket?.description?.toUpperCase(), 125)}
                    </td>

                    <td className="client">
                      {ValidationLength(ticket?.client?.toUpperCase(), 13)}
                    </td>

                    <td className="status">
                      {ValidationLength(ticket?.status?.toUpperCase(), 10)}
                    </td>

                  </tr>

                  <tr>
                    <td colSpan="5">
                      <hr />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <Pagination
              count={totalPages}
              onChange={(_, page) => {
                setCallSettings({ ...callSettings, page })
                getMainContent(page, size)
              }}
              variant="outlined"
              shape="rounded"
              size="small"
              color="primary"
            />

            <ItemsPerPage
              numberOfElements={numberOfElements}
              size={totalElements}
            />
          </div>

          {ticketModal && (
            <TicketsForm
              openModal={ticketModal}
              isModal
              redirectPath={null}
              onClose={toggleTicketsModal}
              setUpdateList={setUpdateList}
            />
          )}

        </Container>
      </LocalizationContext.Provider>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(TicketsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
