/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
  Paper,
  Dialog
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import { LocalizationPlainText } from 'components/Localization';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-tickets-form"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function TicketsForm({
  userInfo,
  ticketsInfo,
  redirectPath,
  onClose,
  setUpdateList,
  createMode,
  openModal,
}) {
  const listStatus = ["TO DO", "WIP", "QA", "DEPLOY", "PROD", "N BUG" ]
  const filter = createFilterOptions();
  const environments = Environment(process.env.REACT_APP_ENV);
  const [open, setOpen] = useState(false);
  const [getOrigin, setOriginId] = useState(userInfo.companiesActive.id);
  const [loading, setLoading] = useState(false);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [messageErr, setMessageErr] = useState('');
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const onDismiss = () => setAlertErr(false);
  const { handleSubmit } = useForm();
  const [editMode, setEditMode] = useState(
    createMode !== true
      ? !!(ticketsInfo.action && ticketsInfo.action.toLowerCase() === 'edit')
      : false
  );
  const [ticketId, setTicketId] = useState(editMode ? ticketsInfo.data._id : '');
  const [description, setDescription] = useState(
    editMode ? ticketsInfo.data.description : ''
  );
  const [type, setType] = useState(editMode ? ticketsInfo.data.type : '')
  const [status, setStatus] = useState(editMode ? ticketsInfo.data.status : '')
  const [jiraId, setJiraId] = useState(editMode ? ticketsInfo.data.jiraId : '')
  const [client, setClient] = useState(editMode ? ticketsInfo.data.client : '')
  const [options, setOptions] = useState([]);
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  useEffect(() => {
    if (messageErr === 'Results found according to these parameters')
      setMessageErr('Ticket já cadastrado');
    else setMessageErr('Erro ao cadastrar');
  }, [alertErr, messageErr]);

  useEffect(() => {
    setEditMode(
      !!(ticketsInfo.action && ticketsInfo.action.toLowerCase() === 'edit')
    );
    setTicketId(editMode ? ticketsInfo.data._id : '');
    setDescription(editMode ? ticketsInfo.data.description : '');
    setJiraId(editMode ? ticketsInfo.data.jiraId : '');
    setClient(editMode ? ticketsInfo.data.client : '');
    setStatus(editMode ? ticketsInfo.data.status : '');
    setType(editMode ? ticketsInfo.data.type : '');
  }, [ticketsInfo, editMode]);

  const onSubmitTickets = () => {
    setLoading(true);
    if (editMode) {
      axios
        .put(
          `${environments.dashBoard}/tickets/${ticketId}`,
          {
            jiraId,
            type,
            description,
            client,
            status,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setMessage({description: error.response.data.message, status: 'error' });
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    } else {
      axios
        .post(
          `${environments.dashBoard}/tickets`,
          {
            jiraId,
            type,
            description,
            client,
            status,
            user: userInfo.user,
          },
          config
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setMessage({description: error.response.data.message, status: 'error' });
          setLoading(false);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    }
  };

  const handleClose = (reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOriginId(userInfo.companiesActive.id);
  }, [userInfo.companiesActive.id]);

  return (
    <Dialog
      className="defaultModal"
      style={{ width: '80%' }}
      maxWidth="xl"
      open={openModal}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-tickets-form"
      transitionDuration={0}
    >
      <Container className="w100" style={{ marginBottom: '0px' }}>
        <DialogTitle
          className="modalHeader navyBlue"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'move',
            fontFamily: 'Lato',
            borderRadius: '4px 4px 0 0'
          }}
          id="draggable-dialog-title-tickets-form"
        >
          <Typography>CADASTRO DE TICKETS</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

      <CommonAlert
        open={alertErr}
        severity={message.status}
        onClose={() => setAlertErr(false)}
        messagePersonal={message.description}
        autoHideDuration={2000}
        width="60%"
      />

        {loading ? <LinearProgress /> : null}

        <div
          className="cardDefault pt16"
          style={{ borderRadius: '0', width: '614px' }}
        >
          <form onSubmit={handleSubmit(onSubmitTickets)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={2} sm={2} className="pl0">
                  <TextField
                    size="small"
                    label={"ID"}
                    name="id"
                    onChange={(e) => setJiraId(e.target.value)}
                    variant="outlined"
                    value={jiraId}
                    fullWidth
                    autoFocus
                    required="required"
                    inputProps={{ maxLength: 5 }}
                    className="textFieldSmall"
                    placeholder={"ID Jira"}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
              </Grid>

              <Grid item xs={3} sm={3} className="pl0">
                  <TextField
                    size="small"
                    label={"Tema"}
                    name="type"
                    onChange={(e) => setType(e.target.value)}
                    variant="outlined"
                    value={type}
                    fullWidth
                    autoFocus
                    required="required"
                    inputProps={{ maxLength: 30 }}
                    className="textFieldSmall"
                    placeholder={"Digite o tema"}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
              </Grid>

       
              <Grid item xs={4} sm={4} className="pl0">
                  <TextField
                    size="small"
                    label={"Restaurante"}
                    name="id"
                    onChange={(e) => setClient(e.target.value)}
                    variant="outlined"
                    value={client}
                    fullWidth
                    autoFocus
                    inputProps={{ maxLength: 30 }}
                    className="textFieldSmall"
                    placeholder={"Informe o restaurante"}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
              </Grid>

              <Grid item xs={3} sm={3} className="pl0">
                 <Autocomplete
                    required
                    options={listStatus}
                    value={status}
                    getOptionLabel={(option) => option}
                    onChange={(_, value) => {
                      setStatus(value);
                    }}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params}
                      </Typography>
                    )}
                    size="small"
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params}
                        type="text"
                        name="status"
                        label="Status"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
              </Grid>

            </Grid>

            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={12} className="pl0">
                  <TextField
                    size="small"
                    label={"Descrição"}
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    value={description}
                    fullWidth
                    autoFocus
                    required="required"
                    inputProps={{ maxLength: 200 }}
                    className="textFieldSmall"
                    placeholder={LocalizationPlainText('TypeHere', 'translations')}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
            </Grid>
           
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Criado com sucesso.
              </Alert>
            </Snackbar>

            <Grid
              item
              xs={12}
              sm={12}
              className="p0 dFlex justifyEnd bgWhite"
              style={{ borderRadius: '0 0 4px 4px' }}
            >

              <Button
                className="defaultButton submitButton"
                type="submit"
                label="Salvar"
                design="contained"
                onSubmit={handleSubmit(onSubmitTickets)}
              />
            </Grid>
          </form>
        </div>
      </Container>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  ticketsInfo: state.tickets
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TicketsForm);
