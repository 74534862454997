/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, Suspense } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import axios from 'axios';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import CmvAndCountSetupDialog from 'components/Dialogs/CmvAndCountSetup';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import FilterOpened from 'images/icons/filters/filter.svg';
import FilterClosed from 'images/icons/filters/filterIconLightBlueNoBgRounded.svg';
import CmvAndCountSetupIcon from 'images/icons/objects/boxesAndPackages/packageGrayConfigYellowLightBlueBgRounded.svg';
import StorageLocationForm from 'pages/inventory/storage-Locations/StorageLocationForm';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  handleMultipleFilters
} from 'utils/handleFilterActions';

import CmvTheoreticalInfos from './CmvTheoreticalInfos';
import PerformedCmvInfos from './PerformedCmvInfos';
import SalesInfos from './SalesInfos';
import SimpleLineGraphic from './SimpleLineGraphic';


const DifferenceInCount = React.lazy(() =>
  import('components/Dialogs/DifferenceInCount')
);

const PerformedCmvDialogInfos = React.lazy(() =>
  import('components/Dialogs/PerformedCmvInfos')
);

function CmvDashboard({ userInfo }) {
  const actualDate = moment();
  const actualDateLessOne = moment(actualDate)?.subtract(1, 'day')?.format('YYYY-MM-DD');
  const actualDateLessSeven = moment(actualDate)?.subtract(7, 'day')?.format('YYYY-MM-DD');
  const { id: paramsID } = useParams();
  const companyActive = userInfo.companiesActive.name;
  const [getId, setId] = useState(paramsID);
  const [filters] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [loading, setLoading] = useState(false);
  const [salesInfoType, setSalesInfoType] = useState(true);
  const [salesInfos, setSalesInfos] = useState({
    loading: true,
    content: ''
  });
  const [cmvTheoreticalInfos, setCmvTheoreticalInfos] = useState({
    loading: true,
    content: ''
  });
  const [cmvPerformedInfos, setCmvPerformedInfos] = useState({
    loading: true,
    content: ''
  });
  const [cmvChartInfos, setCmvChartInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  });
  const environments = Environment(process.env.REACT_APP_ENV);
  const [updateStorageLocations, setUpdateStorageLocations] = useState(false);
  const [loadedStartDateDays, setLoadedStartDateDays] = useState(false);
  const [loadedEndDateDays, setLoadedEndDateDays] = useState(false);
  const [startDateDisabledDays, setStartDateDisabledDays] = useState('');
  const [openFilter, setOpenFilter] = useState(true)
  const [endDateDisabledDays, setEndDateDisabledDays] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [enabledCurrentStartDates, setEnabledCurrentStartDates] = useState([]);
  const [enabledCurrentEndDates, setEnabledCurrentEndDates] = useState([]);
  const [enabledNextStartDates, setEnabledNextStartDates] = useState([]);
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'cmvAndCountSetupDialog', open: false, fullScreen: false },
    { id: 2, name: 'storageLocationDialog', open: false, fullScreen: false },
    { id: 3, name: 'differenceInCount', currentRestaurant: '', open: false, fullScreen: null, maxWidth: null },
    { id: 4, name: 'performedCmvInfos', open: false, fullScreen: null, maxWidth: null },
  ]);

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const getSalesInfos = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setSalesInfos({
      ...salesInfos,
      loading: true,
    });

    axios
      .get(
        `${environments.dashBoard}/cmvCount/sales?restaurantId=${getId}&${params}`
      )
      .then((response) => {
        setSalesInfos({
          loading: false,
          content: response?.data || ''
        });
      })
      .catch((error) => {
        console.log(error);
        setSalesInfos({
          loading: false,
          content: ''
        });
      });
  };

  const getCmvTheoretical = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setCmvTheoreticalInfos({
      ...cmvTheoreticalInfos,
      loading: true,
    });

    axios
      .get(
        `${environments.dashBoard}/cmvCount/theory?restaurantId=${getId}&${params}`
      )
      .then((response) => {
        setCmvTheoreticalInfos({
          loading: false,
          content: response?.data || ''
        });
      })
      .catch((error) => {
        console.log(error);
        setCmvTheoreticalInfos({
          loading: false,
          content: ''
        });
      });
    
    setLoading(false);
  };

  const getCmvPerformed = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setCmvPerformedInfos({
      ...cmvPerformedInfos,
      loading: true,
    });

    axios
      .get(
        `${environments.dashBoard}/cmvCount/realized?restaurantId=${getId}&${params}`
      )
      .then((response) => {
        setCmvPerformedInfos({
          loading: false,
          content: response?.data || ''
        });
      })
      .catch((error) => {
        console.log(error);
        setCmvPerformedInfos({
          loading: false,
          content: ''
        });
      });
    
    setLoading(false);
  };

  const getCmvChartInfos = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setCmvChartInfos({
      ...cmvChartInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cmvCount/graph?restaurantId=${getId}&${params}`

    axios.get(URL)
      .then((response) => {
        const mainObj = response?.data?.content?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.date)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.date)?.map(el => el))

        setCmvChartInfos({
          contentFormatted: [
            {
              title: 'cmv realizado',
              data: mainObj?.map(el => parseFloat(el?.cmv?.realized || 0)),
              dataDiscount: mainObj?.map(el => parseFloat(el?.cmv?.realizedDiscount || 0)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${parseFloat(el?.cmv?.realized || 0).toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}% (R$ ${el?.cmv?.realizedMonetary?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })})`
              })),
              dataFormattedDiscount: mainObj?.map((el, index) => ({
                index,
                item: `${parseFloat(el?.cmv?.realizedDiscount || 0).toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}% (R$ ${(el?.cmv?.realizedDiscountMonetary || 0)?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })})`
              })),
              color: '#5062F0'
            },
            {
              title: 'cmv teórico',
              data: mainObj?.map(el => parseFloat(el?.cmv?.theory || 0)),
              dataDiscount: mainObj?.map(el => parseFloat(el?.cmv?.theoryDiscount || 0)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${parseFloat(el?.cmv?.theory || 0).toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}%  (R$ ${(el?.cmv?.theoryMonetary || 0)?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })})`
              })),
              dataFormattedDiscount: mainObj?.map((el, index) => ({
                index,
                item: `${parseFloat(el?.cmv?.theoryDiscount || 0).toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}%  (R$ ${(el?.cmv?.theoryDiscountMonetary || 0)?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })})`
              })),
              color: '#F2B90D'
            },
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          customYLabelAfter: '%',
          labels: mainObj?.map(el => moment(el?.date)?.format('DD/MM')),
          dates: mainObj?.map(el => el?.date),
          loading: false
        })
      })
      .catch((error) => {
        setCmvChartInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const cmvAndCountSetupButton = () => (
    <Tooltip title="Contagem necessária para compor seu CMV" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="add"
        component="span"
        onClick={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: true } : modal
            )
          );
        }}
      >
        <img
          style={{ width: '25px' }}
          src={CmvAndCountSetupIcon}
          alt="CmvAndCountSetupIcon"
        />
      </IconButton>
    </Tooltip>
  );

  const onDateFilterEndMonthChange = (startDate, endDate, isFirstLoad = false) => {
    const currentStartDate = startDate ? moment(startDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').startOf('month')?.format('YYYY-MM-DD')
    const currentEndDate = endDate ? moment(endDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').endOf('month')?.format('YYYY-MM-DD')

    axios
      .get(
        `${environments.dashBoard}/cmvCount/dates?restaurantId=${getId}&startDate=${currentStartDate}&endDate=${currentEndDate}`
      )
      .then((response) => {
        const defaultSevenDays = [...Array(7).keys()]
        const hasPeriodicity = response?.data?.periodicity
        const enabledCurrentDates = response?.data?.dates?.length
          ? response?.data?.dates?.sort((a, b) => (a > b ? 1 : -1)) 
          : []
        const enabledNextDates = response?.data?.next?.length
          ? response?.data?.next?.sort((a, b) => (a > b ? 1 : -1)) 
          : []
        const disabledDays = enabledCurrentDates?.length
          ? enabledCurrentDates 
          : hasPeriodicity && isFirstLoad
            ? defaultSevenDays?.map(el => moment()?.subtract(el + 1, 'day')?.format('YYYY-MM-DD'))
            : []

        setEnabledCurrentEndDates(enabledCurrentDates)
        setEndDateDisabledDays(disabledDays?.map(el => moment(el)?.format('D')))
        setLoadedEndDateDays(true)
      })
      .catch((error) => {
        console.log(error);
        
      });
  }

  const onMonthStartDateChange = (startDate, endDate, isFirstLoad = false) => {
    const currentStartDate = startDate ? moment(startDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').startOf('month')?.format('YYYY-MM-DD')
    const currentEndDate = endDate ? moment(endDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').endOf('month')?.format('YYYY-MM-DD')
    
    axios
      .get(
        `${environments.dashBoard}/cmvCount/dates?restaurantId=${getId}&startDate=${currentStartDate}&endDate=${currentEndDate}`
      )
      .then((response) => {
        const defaultSevenDays = [...Array(7).keys()]
        const hasPeriodicity = response?.data?.periodicity
        const enabledCurrentDates = response?.data?.dates?.length
          ? response?.data?.dates?.sort((a, b) => (a > b ? 1 : -1)) 
          : []
        const enabledNextDates = response?.data?.next?.length
          ? response?.data?.next?.sort((a, b) => (a > b ? 1 : -1)) 
          : []
        const disabledDays = enabledCurrentDates?.length
          ? enabledCurrentDates
          : hasPeriodicity && isFirstLoad
            ? defaultSevenDays?.map(el => moment()?.subtract(el + 1, 'day')?.format('YYYY-MM-DD'))
            : []
        
        if (isFirstLoad) {
          const useCurrentOrNextDates = enabledNextDates?.length > 1
            ? enabledNextDates
            : enabledCurrentDates
          const useEndCurrentOrNextDates = enabledNextDates?.length >= 1
            ? enabledNextDates
            : enabledCurrentDates
          const arrayPosition = useCurrentOrNextDates?.length === 1 ? -1 : -2
          const currentFirstStartDate = useCurrentOrNextDates?.length
            ? moment(useCurrentOrNextDates?.at(arrayPosition)).add(3, 'hours') 
            : moment(actualDateLessSeven).add(3, 'hours') 
          const currentFirstEndDate = useEndCurrentOrNextDates?.length 
            ? moment(useEndCurrentOrNextDates[useEndCurrentOrNextDates?.length - 1]).add(3, 'hours') 
            : moment(actualDateLessOne).add(3, 'hours');

          setFilterStartDate(currentFirstStartDate)
          setFilterEndDate(currentFirstEndDate)

          setFiltersChanged([
            {
              param: 'startDate',
              value: moment(currentFirstStartDate)?.format('YYYY-MM-DD'),
              urlParam: `startDate=${moment(currentFirstStartDate)?.format('YYYY-MM-DD')}`,
            },
            {
              param: 'endDate',
              value: moment(currentFirstEndDate)?.format("YYYY-MM-DD"),
              urlParam: `endDate=${moment(currentFirstEndDate)?.format("YYYY-MM-DD")}`,
            }
          ])
        }

        onDateFilterEndMonthChange("", "", isFirstLoad)

        setEnabledCurrentStartDates(enabledCurrentDates)
        setEnabledNextStartDates(enabledNextDates)
        setStartDateDisabledDays(disabledDays?.map(el => moment(el)?.format('D')))
        setLoadedStartDateDays(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleStartDateChange = (date, shouldDisableStartDate) => {
    const formattedSelectedDate = format(date, 'yyyy-MM-dd')
    const selectedDay = moment(date)?.format('D')
    const selectedDayIsLastOfMonth = shouldDisableStartDate?.length ? shouldDisableStartDate[shouldDisableStartDate.length - 1] === selectedDay : false
    const selectedDateCurrentIndex = enabledCurrentStartDates.indexOf(formattedSelectedDate)
    const selectedDateCurrentDateEnabled = selectedDateCurrentIndex !== -1 && selectedDateCurrentIndex < enabledCurrentStartDates?.length - 1
      ? enabledCurrentStartDates[selectedDateCurrentIndex + 1]
      : null; 

    setFilterStartDate(date)

    if (selectedDayIsLastOfMonth && enabledNextStartDates?.length) {
      handleMultipleFiltersFunc([
        ['startDate', formattedSelectedDate],
        ['endDate', enabledNextStartDates[0]]
      ]);

      setFilterEndDate(moment(enabledNextStartDates[0]))
    } else {
      handleMultipleFiltersFunc([
        ['startDate', formattedSelectedDate],
        ['endDate', selectedDateCurrentDateEnabled || formattedSelectedDate]
      ]);

      setFilterEndDate(moment(selectedDateCurrentDateEnabled || formattedSelectedDate))
    }
  }

  const handleFinalDateChange = (date) => {
    setFilterEndDate(date)

    if (date < new Date(filterStartDate)) {
      const newDate = moment(date).format('YYYY-MM-DD');
      handleMultipleFiltersFunc([
        ['startDate', newDate],
        ['endDate', newDate]
      ]);

      setFilterStartDate(date);
      return;
    }

    const newDate = moment(date).format('YYYY-MM-DD');
    handleMultipleFiltersFunc([['endDate', newDate]]);
  }

  const customDateFilter = () => (
    <section className="fakeHeaderAndFilterBox">
      <div className="fakeHeaderBox">
        <h1>
          CMV
        </h1>

        <div className="dFlex alignCenter">
          {cmvAndCountSetupButton()}

          <img
            style={{ width: 25 }}
            src={openFilter ? FilterOpened : FilterClosed} 
            onClick={() => {
              setOpenFilter(!openFilter)
            }} 
            className="cursorPointer ml10"
            alt="FilterIcon"
          />
        </div>
      </div>

      <div className={`${!openFilter && 'closedFilter'} fakeFilterBox`}>
        <div className="dFlex pt16 pr16 pb16 pl16 w100">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDatePicker
              margin="normal"
              id="dataInicio"
              variant="inline"
              autoOk
              label='Inicio'
              format='dd/MM'
              value={filterStartDate || null}
              placeholder="Selecione"
              className="dateSmall"
              maxDateMessage="A data inicial deve ser menor que a data final"
              onChange={(value) => {
                const formattedDate = moment(value).format('YYYY-MM-DD')

                if (enabledCurrentStartDates?.includes(formattedDate)) {
                  handleStartDateChange(value, startDateDisabledDays)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              InputLabelProps={{
                shrink: true
              }}
              onMonthChange={(value) => {
                const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
                const endOfMonth   = moment(value).endOf('month').format('YYYY-MM-DD');

                onMonthStartDateChange(startOfMonth, endOfMonth)
              }}
              onOpen={(value) => {
                const startOfMonth = moment(filterStartDate).startOf('month').format('YYYY-MM-DD');
                const endOfMonth   = moment(filterStartDate).endOf('month').format('YYYY-MM-DD');

                onMonthStartDateChange(startOfMonth, endOfMonth)
              }}
              shouldDisableDate={(date) => (
                !startDateDisabledDays?.includes(moment(date)?.format('D'))
              )}
            />

            <span style={{ marginRight: '6px', marginLeft: '6px' }} />

            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              variant="inline"
              autoOk
              label='Fim'
              format='dd/MM'
              value={filterEndDate || null}
              placeholder="Selecione"
              className="dateSmall"
              minDateMessage="A data final deve ser maior que a data inicial"
              onChange={(value) => {
                const formattedDate = moment(value).format('YYYY-MM-DD')

                if (enabledCurrentEndDates?.includes(formattedDate)) {
                  handleFinalDateChange(value)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              InputLabelProps={{
                shrink: true
              }}
              onMonthChange={(value) => {
                const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
                const endOfMonth   = moment(value).endOf('month').format('YYYY-MM-DD');

                onDateFilterEndMonthChange(startOfMonth, endOfMonth)
              }}
              onOpen={(value) => {
                const startOfMonth = moment(filterEndDate).startOf('month').format('YYYY-MM-DD');
                const endOfMonth   = moment(filterEndDate).endOf('month').format('YYYY-MM-DD');

                onDateFilterEndMonthChange(startOfMonth, endOfMonth)
              }}
              shouldDisableDate={(date) => (
                !endDateDisabledDays?.includes(moment(date)?.format('D')) 
              )}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </section>
  )

  useEffect(() => {
    if (loadedStartDateDays && loadedEndDateDays) {
      console.log('filtersChanged', filtersChanged)
      getSalesInfos(filtersChanged);
      getCmvTheoretical(filtersChanged);
      getCmvPerformed(filtersChanged)
    }
  }, [filtersChanged, loadedStartDateDays, loadedEndDateDays]);

  useEffect(() => {
    getCmvChartInfos(filtersChanged)
  }, [paramsID]);

  useEffect(() => {
    setId(paramsID);

    onMonthStartDateChange("", "", true)
  }, [paramsID]);

  return (
    <Layout>
      <TitleHead title="CMV Dashboard" />

      <GlobalStyle />

      <Container className="cmvDashboardPage">
        {customDateFilter()}

        <section className="cmvDashboardContainer">
          <SalesInfos
            salesInfos={salesInfos}
            salesInfoType={salesInfoType}
            setSalesInfoType={setSalesInfoType}
          />

          <div className="dFlex">
            <CmvTheoreticalInfos 
              cmvTheoreticalInfos={cmvTheoreticalInfos}
              salesInfoType={salesInfoType}
            />

            <PerformedCmvInfos
              cmvPerformedInfos={cmvPerformedInfos}
              salesInfoType={salesInfoType}
              setModalSettings={setModalSettings}
              modalSettings={modalSettings}
              filtersChanged={filtersChanged}
              companyActive={companyActive}
            />
          </div>

          <div className="cmvChartInfosCard">
            <SimpleLineGraphic
              paramsID={paramsID}
              mainContent={cmvChartInfos}
              salesInfoType={salesInfoType}
              // actualDateLessOne={actualDateLessOne}
              // actualDateLessFourteen={actualDateLessFourteen}
            />
          </div>
        </section>
      </Container>

      <CommonAlert
        open={!!message.description}
        onClose={() => {
          setMessage({ description: '', status: '' })
        }}
        severity={message.status}
        indexMessage={message.description}
        messagePersonal={message.description}
      />

      {modalSettings.find((modal) => modal.name === 'cmvAndCountSetupDialog').open && (
        <Suspense fallback={<span />}>
          <CmvAndCountSetupDialog
            open={modalSettings.find((modal) => modal.name === 'cmvAndCountSetupDialog').open}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
            updateStorageLocations={updateStorageLocations}
            setMessage={setMessage}
            userInfo={userInfo}
            defaultDate={filtersChanged?.find(el => el?.param === 'endDate')?.value}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'differenceInCount')
        .open && (
        <Suspense fallback={<span />}>
          <DifferenceInCount
            open={
              modalSettings.find((modal) => modal.name === 'differenceInCount')
                .open
            }
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'differenceInCount'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'performedCmvInfos')
        .open && (
        <Suspense fallback={<span />}>
          <PerformedCmvDialogInfos
            open={
              modalSettings.find((modal) => modal.name === 'performedCmvInfos')
                .open
            }
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'performedCmvInfos'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
            startDate={filtersChanged?.find(el => el?.param === 'startDate')?.value}
            endDate={filtersChanged?.find(el => el?.param === 'endDate')?.value}
            initialStockTotal={cmvPerformedInfos?.content?.initialStock || 0}
            shoppingTotal={cmvPerformedInfos?.content?.orders || 0}
            transfersTotal={cmvPerformedInfos?.content?.transfers || 0}
            finalStockTotal={cmvPerformedInfos?.content?.finalStock || 0}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'storageLocationDialog').open && (
        <StorageLocationForm
          isModal
          openModal={modalSettings.find((modal) => modal.name === 'storageLocationDialog').open}
          redirectPath={null}
          setUpdateList={setUpdateStorageLocations}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'storageLocationDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          createMode
        />
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(CmvDashboard);