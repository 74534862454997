/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import {
  AppBar,
  CssBaseline,
  Drawer,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  Popover,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Menu } from '@material-ui/icons';
import clsx from 'clsx';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CompaniesCombo from 'components/Companies/companies';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import NewPassword from 'components/Dialogs/NewPassword/index';
import ChangeUser from 'components/Dialogs/ChangeUser/changeUser'
import PaperComponent from 'components/PaperComponent';
import NewSideBarMenu from 'components/SideBar/NewSideBar/newSideBarMenu';
import Sidebar from 'components/SideBar/sidebar';
import { useUserGroups } from 'hooks/useUserGroups';
import SearchHeader from 'images/icons/searches/searchHeader.svg';
import Profile from 'images/icons/users/profile.svg';
import { ReactComponent as BloomIcon } from 'images/logo/Logomarca_Bloom_Branco.svg';
import * as UserActions from 'store/actions/user';
import { clearAll } from 'store/reducers/quotationSelectProducts';
import localStorageManager from 'utils/localStorageManager';

import SearchModal from './SearchModal/index';
import ResumedSearchModal from './SearchModal/ResumedSearchModal';
import { MobileMenu } from './styles';

const drawerWidth = 310;

const styles = {
  root: {
    flexGrow: 1,
    width: 'relative',
    position: 'fixed',
    top: '35px',
    right: 'auto',
    bottom: 'auto',
    left: '248px'
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    paddingTop: 0,
    backgroundColor: '#080B30',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    borderRight: 'none'
  },
  drawerClose: {
    paddingTop: 0,
    backgroundColor: '#080B30',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '50px',
    borderRight: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      position: 'relative',
      width: 'calc(100vw - 50px)',
      padding: '15px 5px 0px 10px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '6px 24px',
      margin: '15px 0'
    },
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      margin: '0px !important',
      position: 'relative',
      width: 'calc(100vw - 50px)',
      padding: '15px 10px 0px 10px',
      zIndex: 0
    },
    height: 'calc(100vh - 65px)',
    width: 'calc(100vw - 50px)',
    overflow: 'auto'
  },
  noMargin: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: 'calc(100vw - 50px)',
    flex: 1,
    height: 'calc(100vh - 65px)',
    paddingBottom: '15px',
    background: '#E5E5E5'
  },

  settings: {
    padding: '12px',
    backgroundColor: '#0F1225',
    color: '#fff',
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  buttonSettings: {
    backgroundColor: 'transparent',
    color: '#fff',
    border: 'none',
    fontSize: '16px',
    lineHeight: '20px',
    margin: '4px 0px'
  }
}));

function MiniDrawer({
  userInfo,
  grayBackground,
  companyFirstSelect,
  companiesActive,
  homeGrid,
  children,
  noMarginGrid
}) {
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const sessionExpireTime = localStorage.getItem('sessionExpireCountdown');
  const currentVersion = localStorage.getItem('version');
  const smallTabletScreen = useMediaQuery('(max-width: 602px)');
  const { id: restaurantId } = useParams();
  const classes = useStyles();
  const { pathname, search } = useLocation();
  const { companiesIds } = useUserGroups();
  const history = useHistory();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hoveredOut, setHoveredOut] = useState(false);
  const [state, setState] = useState({ left: false });
  const [missions, setMissions] = useState('');
  const [indicators, setIndicators] = useState('');
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalNotifications, setOpenModalNotifications] = useState(false);
  const [openNewPassword, setOpenNewPassword] = useState(false);
  const [openChangeUser, setOpenChangeUser] = useState(false)
  const [confirmModal, setConfirmModal] = useState({
    name: '',
    action: '',
    pathName: '',
    open: false,
    title: '',
    confirmText: '',
    content: '',
    onConfirm: '',
    onCancel: ''
  });

  const { id: companieActiveId } =
    localStorageManager.getLocalStorage('companiesActive');

  const storedQuotationProductsSelected = useSelector(
    (currState) => currState.quotation.products
  );

  const openSettings = !!anchorEl;
  const profileSettings = open ? 'simple-popover' : undefined;
  const isProvider = userInfo?.profile?.[0]?.[0]?.name === 'providers';
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const isAdminMaster = userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const isQuoter = userInfo?.profile?.[0]?.[0]?.name === 'quoter';
  const isCataloguer = userInfo?.profile?.[0]?.[0]?.name === 'cataloguer';
  const isNfes = userInfo?.profile?.[0]?.[0]?.name === 'nfes';
  const isQuotationPage = window.location.pathname.includes('/cotacao/gestor/compras');

  const [timeLeft, setTimeLeft] = useState(sessionExpireTime);

  const handleClickOpenModalSearch = () => {
    setOpenModalSearch(true);
  };

  const handleCloseModalSearch = () => {
    setOpenModalSearch(false);
  };

  const handleClickSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    localStorage.removeItem('checkQueueOn');
    localStorage.removeItem('token');
    localStorage.removeItem('countConfigs');
    localStorage.removeItem('productionConfigs');
    localStorage.removeItem('sessionExpireCountdown');
    localStorageManager.cleanCurrentLocalStorage('companiesActive');
    localStorageManager.cleanCurrentLocalStorage('eanInfos');
    companyFirstSelect(false);
    history.push('/login');
  };

  const handleSignOut = () => {
    if (storedQuotationProductsSelected?.length && isQuotationPage) {
      setConfirmModal({
        ...confirmModal,
        name: 'quotation',
        action: 'signOut',
        open: true,
        confirmText: 'Sim',
        cancelText: 'Não',
        content: (
          <Typography className="bold">
            Você perderá todos os itens selecionados, deseja continuar?
          </Typography>
        )
      });
    } else {
      signOut();
    }
  };

  const getUsers = () => { }
  const callSettings = {}

  const onConfirmConfirmationDialogButton = () => {
    if (confirmModal?.name === 'quotation') {
      dispatch(clearAll());

      confirmModal?.action === 'signOut'
        ? signOut()
        : history.push(confirmModal?.redirectPath);
    }

    if (confirmModal?.name === 'wrongRestaurant') {
      history.push(confirmModal?.redirectPath);
      companiesActive(
        stores[0].find((item) => item.id === companiesIds[0]).id,
        stores[0].find((item) => item.id === companiesIds[0])
          .name,
        stores[0].find((item) => item.id === companiesIds[0])
          .index,
        stores[0].find((item) => item.id === companiesIds[0])?.segment
      );

      setConfirmModal({
        action: '',
        pathName: '',
        redirectPath: '',
        open: false,
        title: '',
        content: '',
        confirmText: '',
        onConfirm: '',
        onCancel: '',
        cancelText: ''
      });
    }
  };

  const onCancelConfirmationDialogButton = () => {
    if (confirmModal?.name === 'quotation') {
      setConfirmModal({
        action: '',
        pathName: '',
        redirectPath: '',
        open: false,
        title: '',
        content: '',
        confirmText: '',
        onConfirm: '',
        onCancel: '',
        cancelText: ''
      });
    }
  };

  const mouseEnterHandler = () => {
    setHovered(true);
    setHoveredOut(false);
  };

  const mouseLeaveHandler = () => {
    setHovered(false);
    setHoveredOut(true);
  };

  const onTimeout = () => {
    setOpen(true);
  };

  const onLeaveTimeout = () => {
    setOpen(false);
  };

  const handleClickOpenNotifications = () => {
    setOpenModalNotifications(true);
  };

  const handleClose = () => {
    setOpenModalNotifications(false);
  };

  const toggleDrawer = useCallback(
    (side, isOpen) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      setState((prevState) => ({ ...prevState, [side]: isOpen }));
    },
    []
  );

  const sideList = useCallback(
    (side) => (
      <div
        className={(classes.list, 'p0')}
        role="presentation"
        onKeyDown={toggleDrawer(side, false)}
      >
        <Divider />
        <Sidebar open={state.left} />
      </div>
    ),
    [classes.list, state.left, toggleDrawer]
  );

  const handleClickOpenNewPassword = () => {
    setOpenNewPassword(true);
  };

  const handleCloseNewPassword = () => {
    setOpenNewPassword(false);
  };

  const handleClickOpenChangeUser = () => {
    setOpenChangeUser(true)
    handleCloseSettings()
  }

  const handleCloseChangeUser = () => {
    setOpenChangeUser(false)
  }

  const currentUserRedirect = () => {
    switch (userInfo?.profile?.[0]?.[0]?.name) {
      case 'nfes':
        return `/nfes-vendas/${companieActiveId}`;
      case 'admin':
        return `/admin/catalogacao/${companieActiveId}`;
      case 'quoter':
        return `/cotacao/gestor/compras/${companieActiveId}`;
      default:
        return `/${companieActiveId}`;
    }
  };

  const canShowChangeUser = () => {
    let hasOperationalUsers = userInfo?.operationalUsers && Array.isArray(userInfo?.operationalUsers) && userInfo?.operationalUsers.length > 0

    return hasOperationalUsers
  }

  const setProfileName = (profileInfo, translate = true) => {
    let name = ""

    if (profileInfo && Array.isArray(profileInfo) && profileInfo.length > 0) {
      profileInfo = profileInfo[0][0]

      if (profileInfo?.name) {

        name = profileInfo?.name

        if (translate) {
          switch (profileInfo.name) {
            case "restaurants":
              name = "Admin Restaurante"
              break
            case "admin":
              name = "Admin Plataforma"
              break
            case "stockists":
              name = "Estoquista"
              break
            case "producers":
              name = "Produção"
              break
            case "managers":
              name = "Gestor"
              break
            case "buyers":
              name = "Comprador"
              break
            case "quoter":
              name = "Cotador"
              break
            case "cataloguer":
              name = "Catalogador"
              break
            case "providers":
              name = "Fornecedor"
              break
            case "nfes":
              name = "NFE"
              break
            case "manufactures":
              name = "Cozinha"
              break
            case "quoterList":
              name = "Cotador Listagem"
              break
            case "admin_master":
              name = "Admin Master"
              break
            default:
              name = ""
              break
          }
        }
      }
    }

    return name
  }

  const renderSearchModal = () => {
    if (isQuoter) {
      return (
        <ResumedSearchModal
          getId={companieActiveId}
          openModal={openModalSearch}
          closeModal={handleCloseModalSearch}
        />
      );
    }

    return (
      <SearchModal
        getId={companieActiveId}
        openModal={openModalSearch}
        closeModal={handleCloseModalSearch}
      />
    );
  };

  const getCurrentPathWithoutLastPart = () => {
    return pathname.slice(0, pathname.lastIndexOf('/'))
  }

  useEffect(() => {
    if (restaurantId === undefined && window.location.pathname === '/') {
      const restaurantId = JSON.parse(localStorage.getItem('companiesActive'))?.value?.id;
      return window.location.href = `/${restaurantId}`
    }
    if (!companiesIds?.includes(parseFloat(restaurantId))) {
      setConfirmModal({
        ...confirmModal,
        open: true,
        title: 'Você não tem permissão para acessar este restaurante',
        confirmText: 'Confirmar',
        name: 'wrongRestaurant',
        redirectPath: `${getCurrentPathWithoutLastPart()}/${companiesIds[0]}`,
        content: (
          <Typography className="fontSizeDefault">
            Redirecionado para um restaurante válido
          </Typography>
        )
      });
    }
  }, [search]);

  useEffect(() => {
    if (isQuoter && window.location.pathname === '/') {
      history.push(`/cotacao/gestor/compras/${companieActiveId}`);
    }

    if (userInfo?.profile?.[0]?.[0]?.name === 'nfes') {
      history.push(`/nfes-vendas/${companieActiveId}`);
    }
  }, [companieActiveId, history, userInfo?.profile]);

  useEffect(() => {
    if (userInfo.companiesActive.id === 2) {
      setMissions('16');
      setIndicators('22');
    } else if (userInfo.companiesActive.id === 1) {
      setMissions('13');
      setIndicators('17');
    } else if (userInfo.companiesActive.id === 4) {
      setMissions('8');
      setIndicators('10');
    }
  }, [userInfo.companiesActive.id]);

  useEffect(() => {
    const timer = hovered && setTimeout(onTimeout, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [hovered]);

  useEffect(() => {
    const timer = hoveredOut && setTimeout(onLeaveTimeout, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [hoveredOut]);

  useEffect(() => {
    if (
      (isAdmin || isCataloguer || isAdminMaster) &&
      (window.location.pathname === `/indicadores/${companieActiveId}` ||
        window.location.pathname === `/${companieActiveId}` ||
        window.location.pathname === `/`)
    ) {
      history.push(`/admin/catalogacao/${companieActiveId}`);
    }
  }, [companieActiveId, history, isAdmin, isCataloguer, isAdminMaster]);

  useEffect(() => {
    const handleStorage = () => {
      const storageToken = localStorage.getItem('token');
      if (!storageToken) {
        history.push('/login');
      }
    };

    window.addEventListener('storage', handleStorage);

    return () => window.removeEventListener('storage', handleStorage);
  }, [history]);

  useEffect(() => {
    if (isQuoter && window.location.pathname === '/') {
      history.push(`/cotacao/gestor/compras/${companieActiveId}`);
    }

    if (userInfo?.profile?.[0]?.[0]?.name === 'nfes') {
      history.push(`/nfes-vendas/${companieActiveId}`);
    }
  }, [
    companieActiveId,
    companiesIds,
    history,
    location.key,
    restaurantId,
    userInfo?.profile
  ]);

  useEffect(() => {
    if (!parseFloat(sessionExpireTime)) {
      window.location.reload();
      signOut();
    }

    const intervalId = setInterval(() => {
      localStorage.setItem('sessionExpireCountdown', sessionExpireTime - 1);
      setTimeLeft(sessionExpireTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const checkInactivity = () => {
    let expireTime = localStorage.getItem("expireTime")
    expireTime = expireTime ? parseInt(expireTime) : undefined

    if (expireTime) {
      if (expireTime < Date.now()) {
        handleClickOpenChangeUser()
      }
    }
  }

  const updateExpiryTime = () => {
    let countdownSeconds = localStorage.getItem("changeUserExpireCountdown")
    countdownSeconds = countdownSeconds ? parseInt(countdownSeconds) : undefined

    if (countdownSeconds) {
      const expireTime = Date.now() + (countdownSeconds * 1000)
      localStorage.setItem("expireTime", expireTime.toString())
    } else {
      localStorage.removeItem("expireTime")
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactivity()
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    updateExpiryTime()
    window.addEventListener('click', updateExpiryTime)
    window.addEventListener('keypress', updateExpiryTime)
    window.addEventListener('scroll', updateExpiryTime)
    window.addEventListener('mousemove', updateExpiryTime)

    return () => {
      window.removeEventListener('click', updateExpiryTime)
      window.removeEventListener('keypress', updateExpiryTime)
      window.removeEventListener('scroll', updateExpiryTime)
      window.removeEventListener('mousemove', updateExpiryTime)
    }
  }, [])

  const reloadPage = useCallback(async () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });

    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, []);

  // useDidMountEffect(() => {
  //   reloadPage();
  // }, [currentVersion]);

  return (
    <div
      className="layoutContainer"
      style={{
        backgroundColor: grayBackground && '#e5e5e5',
        display: 'flex'
      }}
    >
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        style={{ background: '#080B30' }}
      >
        <Toolbar
          style={{
            background:
              process.env.REACT_APP_ENV === 'development'
                ? '#990000'
                : '#080B30',
            paddingLeft: '0',
            paddingRight: '0',
            minHeight: 'auto',
            justifyContent: 'space-between'
          }}
        >
          <Hidden>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer('left', !state.left)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <Menu />
            </IconButton>
          </Hidden>

          <section className="m0 dFlex">
            <div
              className="header-logo-box dFlex"
              style={{
                background:
                  process.env.REACT_APP_ENV === 'development'
                    ? '#ff0000'
                    : '#5062F0'
              }}
            >
              <Link style={{ color: '#FFF' }} to={currentUserRedirect}>
                <BloomIcon
                  className="m0 dFlex"
                  style={{ width: 'relative', height: '35px' }}
                />
              </Link>
            </div>

            <div className="header-restaurants-box">
              {/* {!isNfes && window.location.pathname !== `/${companieActiveId}` && window.location.pathname !== `/indicadores/${companieActiveId}` ? <CompaniesCombo /> : null} */}
              {!isNfes ? <CompaniesCombo /> : null}
            </div>
          </section>

          <section className="headerRight">
            {!isProvider && !isAdmin && !isNfes && !isCataloguer && !isAdminMaster && (
              <section className="search-modal">
                <div style={{ marginRight: '24px' }}>
                  <button
                    className="noStyle"
                    tabIndex={0}
                    onClick={handleClickOpenModalSearch}
                    type="button"
                  >
                    <img
                      src={SearchHeader}
                      alt="Search"
                      width="auto"
                      height="auto"
                    />
                  </button>
                </div>
              </section>
            )}

            {/* {(!showRestaurantInfos && !isProvider && !isAdmin && !isQuoter && !isNfes && !isCataloguer) &&
              <section className="headerInfosBox">

                <div className="headerInfos">
                  <p>
                    {missions} missões
                  </p>

                  <p>
                    {indicators} indicadores
                  </p>
                </div>

                <div className="headerInfosIcons">
                  <div className="iconBox">
                    <div className="wrapper bloom" style={{ height: userInfo.companiesActive.id === 2 ? "100%" : userInfo.companiesActive.id === 1 ? "60%" : "20%" }} />

                    <img src={wrench} alt="wrench" />
                  </div>

                  <div className="iconBox">
                    <div className="wrapper diary" style={{ height: userInfo.companiesActive.id === 2 ? "10%" : userInfo.companiesActive.id === 1 ? "20%" : "30%" }} />

                    <img src={graph} alt="graph" />
                  </div>
                </div>
              </section>
            } */}

            <section className="dFlex userProfileImageBox" style={{ paddingRight: '15px' }}>
              <div
                aria-describedby={profileSettings}
                onClick={handleClickSettings}
                className="userName dFlex white alignCenter cursorPointer"
                style={{ fontSize: '16px' }}
                role="button"
                tabIndex={0}
              >
                <span
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <b>{!isProvider ? userInfo.user : ''}</b>
                  <span
                    style={{
                      fontSize: "10px",
                      flex: "1",
                      textAlign: "right"
                    }}
                  >
                    {!isProvider ? setProfileName(userInfo.profile) : ""}
                  </span>
                </span>
                &nbsp;&nbsp;
              </div>

              <Popover
                className="popover-settings"
                id={profileSettings}
                open={openSettings}
                anchorEl={anchorEl}
                onClose={handleCloseSettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <div className={classes.settings}>
                  {
                    canShowChangeUser() ?
                      <button
                        type="button"
                        className={classes.buttonSettings}
                        onClick={handleClickOpenChangeUser}
                      >
                        Trocar usuário
                      </button>
                      :
                      ""
                  }
                  <button
                    type="button"
                    className={classes.buttonSettings}
                    onClick={handleClickOpenNewPassword}
                  >
                    Alterar senha
                  </button>
                  <button
                    type="button"
                    className={classes.buttonSettings}
                    onClick={() => handleSignOut()}
                  >
                    Sair
                  </button>
                </div>
              </Popover>

              <NewPassword
                open={openNewPassword}
                userInfo={userInfo.user}
                close={handleCloseNewPassword}
                setMessage={setMessage}
                callSettings={callSettings}
                getUsers={getUsers}
              />

              <ChangeUser
                open={openChangeUser}
                userInfo={userInfo}
                close={handleCloseChangeUser}
                setProfileName={setProfileName}
              />

              <Tooltip onClick={handleClickSettings}>
                <div style={{ display: 'flex' }}>
                  <img
                    src={Profile}
                    alt="Perfil"
                    style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                  />
                </div>
              </Tooltip>
            </section>
          </section>
        </Toolbar>
      </AppBar>

      <div className="header-restaurants-box responsive">
        <CompaniesCombo responsive />
      </div>

      <div className="onlyOnMobileBloom">
        <Hidden>
          <SwipeableDrawer
            open={state.left}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {sideList('left')}
          </SwipeableDrawer>
        </Hidden>
      </div>

      <Hidden only={['xs', 'sm', 'md']}>
        <MobileMenu>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            onMouseOver={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
          >
            <Sidebar
              open={open}
              handleClickOpenNotifications={handleClickOpenNotifications}
            />
          </Drawer>
        </MobileMenu>

        <NewSideBarMenu
          signOut={handleSignOut}
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
          storedQuotationProductsSelected={storedQuotationProductsSelected}
          isQuotationPage={isQuotationPage}
        />
      </Hidden>

      {homeGrid ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {children}
        </div>
      ) : noMarginGrid ? (
        <main className={classes.noMargin}>
          <div className="main-content">{children}</div>
        </main>
      ) : (
        <main className={`${classes.content} ${smallTabletScreen && 'smallTabletMainContainer'}`}>
          <div className="main-content">{children}</div>
        </main>
      )}

      <Dialog
        style={styles.root}
        open={openModalNotifications}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        autoDetectWindowHeight
      >
        <DialogTitle
          className="textAlignCenter cursorMove"
          id="draggable-dialog-title"
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{
              background: 'none',
              position: 'absolute',
              top: '0',
              right: '0',
              align: 'right'
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <iframe
            title="Notificações"
            src="/Notifications"
            width="400"
            height="500"
            position="absolute"
            frameBorder="0"
            allowFullScreen
          />
        </DialogContent>
      </Dialog>

      {confirmModal?.open && (
        <ConfirmModal
          open={confirmModal?.open}
          title={confirmModal?.title}
          content={confirmModal?.content}
          onConfirm={onConfirmConfirmationDialogButton}
          confirmText={confirmModal?.confirmText}
          onClose={onCancelConfirmationDialogButton}
          cancelText={confirmModal?.cancelText}
        />
      )}

      {openModalSearch && renderSearchModal()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
