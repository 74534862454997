import assortment from './assortment';
import brands from './brands';
import tickets from './tickets';
import cards from './cards';
import cardsFilter from './cardsFilterSlice';
import count from './count';
import countFilter from './countFilterSlice';
import discards from './discards';
import fornecedores from './fornecedores';
import loading from './LoadingSlice';
import menu from './menu';
import order from './order';
import periodicities from './periodicities';
import producers from './producers';
import productionsFilter from './productionsFilterSlice';
import products from './products';
import productsFilter from './productsFilterSlice';
import projection from './projection';
import quotation from './quotationSelectProducts';
import simpleModalSlice from './simpleModalSlice';
import skus from './skus';
import squares from './squares'
import process from './process'
import storageLocations from './storageLocations';
import cancellationReasons from './cancellationReasons';
import unitsMeasurements from './unitsMeasurements';
import user from './user';
import userConfigs from './userConfigs';

export default {
  cancellationReasons,
  assortment,
  user,
  userConfigs,
  count,
  projection,
  order,
  unitsMeasurements,
  producers,
  brands,
  skus,
  discards,
  products,
  cards,
  fornecedores,
  periodicities,
  storageLocations,
  squares,
  menu,
  loading,
  productionsFilter,
  productsFilter,
  countFilter,
  process,
  cardsFilter,
  quotation,
  tickets,
  simpleModalSlice
};
