/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useMemo,
  Suspense,
  useEffect,
  lazy,
  useCallback
} from 'react';

import 'moment/locale/pt-br';

import DateFnsUtils from '@date-io/date-fns';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Tooltip,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  LinearProgress,
  useMediaQuery
} from '@material-ui/core';
import { PlayCircleOutline } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Axios from 'axios';
import { differenceInDays, isToday, isTomorrow } from 'date-fns';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';
import Moment from 'moment';
import moment from 'moment/moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams, Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import PaperComponent from 'components/PaperComponent';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ProdIcon from 'images/icons/lettersAndWords/words/prodWhiteIcon.svg';
import SetupIcon from 'images/icons/objects/clipboards/setupIcon.svg';
import ProductSetupIcon from 'images/icons/objects/pencils/productSetup.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import AlertIcon from 'images/icons/signals/alerts/alertRedWithBg.svg';
import CheckedIcon from 'images/icons/signals/checks/checkGreenWithBg.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import DialogCount from 'pages/inventory/Counts/dialogs/DialogCount';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as CountActions from 'store/actions/count';
import { updateFilter, clearFilter } from 'store/reducers/countFilterSlice';
import { ButtonChip } from 'styles/buttons';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import history from 'utils/history';
import ValidationLength from 'utils/utils';

import CountTable from './CountTable';
import { TrDesk, TrTablet, DesktopTableWrapper } from './styles';

import CountsMobile from '../mobile';

const environments = Environment(process.env.REACT_APP_ENV);

const OutOfCountProductsDialog = lazy(() =>
  import('pages/inventory/Counts/dialogs/OutOfCountProductsDialog')
);
const ProductSetupsDialog = lazy(() =>
  import('pages/catalog/products/dialogs/ProductSetups')
);

const actualDate = new Date();
const actualDateLessSeven = actualDate.setDate(actualDate.getDate() - 7);

export function CountsList({ userInfo, setCountId }) {
  const { id: paramsID } = useParams();
  const queryParams = useLocation()?.search;
  const isMobile = useMediaQuery('(max-width: 821px)');

  const [page, setPage] = useState(1);
  const [date, setDate] = useState(new Date());
  const [hasChangeForm, setHasChangeForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState({});
  const [statusCode, setStatusCode] = useState()
  const [inventories, setInventories] = useState([]);
  const [inventoriesModal, setInventoriesModal] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [productDialogList, setProductDialogList] = useState([]);
  const [productDialogTitle, setProductDialogTitle] = useState({});
  const [totalDiffValue, setTotalDiffValue] = useState(0);
  const [outOfCountProducts, setOutOfCountProducts] = useState(null);
  const [alertErr, setAlertErr] = useState(false);
  const [alertErrItens, setAlertErrItens] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [getAlert, setAlert] = useState('');
  const [openSetupModal, setOpenSetupModal] = useState(false);
  const [countsPendingModal, setCountsPendingModal] = useState(false);
  const [countGroup, setCountGroup] = useState({
    state: false,
    counts: {},
    period: {}
  });
  const [selectedCount, setSelectedCount] = useState();
  const [blockButton, setBlockButton] = useState(false)
  const [loading, setLoading] = useState(true);
  const prevPath = history?.location?.search?.includes('prevPath');
  const prevPathEmpty = history?.location?.search?.includes(' ') || !history?.location?.search;
  const dispatch = useDispatch();
  const [differenceQueryParam, setDifferenceQueryParam] = useState(
    queryParams?.includes('difference')
      ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('difference'))
        ?.split('=')[1]
      : ''
  );
  const [initialDateFilterDefaultValue, setInitialDateFilterDefaultValue] =
    useState(
      queryParams?.includes('startAt')
        ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('startAt'))
          ?.split('=')[1]
        : ''
    );

  const [endDateFilterDefaultValue, setEndDateFilterDefaultValue] = useState(
    queryParams?.includes('endAt')
      ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('endAt'))
        ?.split('=')[1]
      : ''
  );

  const [productQueryParam, setProductQueryParam] = useState({
    description: queryParams?.includes('productDescription')
      ? decodeURI(
        queryParams
          ?.split('&')
          ?.find((el) => el.includes('productDescription'))
          ?.split('=')[1]
      )
      : '',
    id: queryParams?.includes('productId')
      ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('productId'))
        ?.split('=')[1]
      : ''
  });

  const [periodQueryParam, setPeriodQueryParam] = useState({
    description: queryParams?.includes('periodDescription')
      ? decodeURI(
        queryParams
          ?.split('&')
          ?.find((el) => el.includes('periodDescription'))
          ?.split('=')[1]
      )
      : '',
    id: queryParams?.includes('periodId')
      ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('periodId'))
        ?.split('=')[1]
      : ''
  });

  const diffFilterDefaultValue =
    differenceQueryParam === 'leftover'
      ? { title: 'Sobra', value: 'leftover' }
      : differenceQueryParam === 'noDiff'
        ? { title: 'Sem diferença', value: 'noDiff' }
        : differenceQueryParam === 'all' 
          ? { title: 'Todos', value: '' }
          : differenceQueryParam
            ? { title: 'Falta', value: 'lack' }
            : undefined;

  const [modalStates, setModalStates] = useState({
    modalOutOfCountProducts: false,
    modalProductSetups: false,
    modalEmpty: false,
    productDialog: false,
    confirmModal: false
  });

  const [confirmModalInfos, setConfirmModalInfos] = useState({
    name: '',
    contentText: ''
  });

  const [selectedProduct, setSelectedProduct] = useState();
  const defaultDateFilters = useMemo(
    () => [
      {
        param: 'startDate',
        value:
          initialDateFilterDefaultValue ||
          format(actualDateLessSeven, 'yyyy-MM-dd'),
        urlParam: `startDate=${initialDateFilterDefaultValue ||
          format(actualDateLessSeven, 'yyyy-MM-dd')
          }`
      },
      {
        param: 'endDate',
        value: endDateFilterDefaultValue || format(actualDate, 'yyyy-MM-dd'),
        urlParam: `endDate=${endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
          }`
      }
    ],
    [endDateFilterDefaultValue, initialDateFilterDefaultValue]
  );

  const storedCountsFilter = useSelector(
    (currState) => currState.countFilter.filters
  );

  const storedCountsFilterFormatted = useMemo(
    () =>
      storedCountsFilter?.some(
        (el) => el?.filterName === 'startDate' || el?.filterName === 'endDate'
      )
        ? storedCountsFilter.some((item) => item.filterName === 'startDate') ?
          [
            ...storedCountsFilter,
            !storedCountsFilter.some((item) => item.filterName === 'endDate') &&
            {
              endDate:
                endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd'),
              filterName: 'endDate',
              textValue:
                endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
            }
          ]
          :
          storedCountsFilter.some((item) => item.filterName === 'endDate') ?
          [
            ...storedCountsFilter,
            !storedCountsFilter.some((item) => item.filterName === 'startDate') &&
            {
              startDate:
                initialDateFilterDefaultValue ||
                format(actualDateLessSeven, 'yyyy-MM-dd'),
              filterName: 'startDate',
              textValue:
                initialDateFilterDefaultValue ||
                format(actualDateLessSeven, 'yyyy-MM-dd')
            }
          ]
          :
          storedCountsFilter
        : [
          ...storedCountsFilter,
          {
            startDate:
              initialDateFilterDefaultValue ||
              format(actualDateLessSeven, 'yyyy-MM-dd'),
            filterName: 'startDate',
            textValue:
              initialDateFilterDefaultValue ||
              format(actualDateLessSeven, 'yyyy-MM-dd')
          },
          {
            endDate:
              endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd'),
            filterName: 'endDate',
            textValue:
              endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
          },
        ],
    [
      endDateFilterDefaultValue,
      initialDateFilterDefaultValue,
      storedCountsFilter
    ]
  );

  const shouldUseStoredFilters = useMemo(
    () =>
      (storedCountsFilterFormatted && prevPath) ||
      (storedCountsFilterFormatted && prevPathEmpty),
    [prevPath, prevPathEmpty, storedCountsFilterFormatted]
  );

  const DEFAULT_FILTERS = useMemo(
    () => [
      ...defaultDateFilters,
      productQueryParam?.description && {
        param: 'productId',
        value: productQueryParam?.id,
        urlParam: `productId=${productQueryParam?.id}`
      },
      periodQueryParam?.description && {
        param: 'periodicitiesId',
        value: periodQueryParam?.id,
        urlParam: `periodicitiesId=${periodQueryParam?.id}`
      },
      diffFilterDefaultValue && {
        param: 'difference',
        value: diffFilterDefaultValue?.value,
        urlParam: `difference=${diffFilterDefaultValue?.value}`
      }
    ],
    [
      defaultDateFilters,
      diffFilterDefaultValue,
      productQueryParam?.description,
      productQueryParam?.id,
      periodQueryParam?.description,
      periodQueryParam?.id
    ]
  );
  const [filters, setFilters] = useState(
    shouldUseStoredFilters ? storedCountsFilterFormatted : DEFAULT_FILTERS
  );
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [backButtonConfig, setBackButtonConfig] = useState(null);
  const [openAdditionalItens, setOpenAdditionalItens] = useState(false);
  const [salesProcessInfos, setSalesProcessInfos] = useState();

  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        initialDate: shouldUseStoredFilters
          ? Moment(
            storedCountsFilterFormatted.find(
              (filter) => filter.filterName === 'startDate'
            )?.startDate
          ).add(3, 'hours')
          : initialDateFilterDefaultValue
            ? Moment(initialDateFilterDefaultValue).add(3, 'hours')
            : actualDateLessSeven,
        endDate: shouldUseStoredFilters
          ? Moment(
            storedCountsFilterFormatted.find(
              (filter) => filter.filterName === 'endDate'
            )?.endDate
          ).add(3, 'hours')
          : endDateFilterDefaultValue
            ? Moment(endDateFilterDefaultValue).add(3, 'hours')
            : ''
      },
      {
        label: 'Contagem',
        filterName: 'periodicitiesId',
        placeHolder: 'Selecione',
        size: 'medium',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/periodicities`,
        key: 'periodicities',
        optionReference: 'description',
        options: [],
        defaultValue: periodQueryParam,
        storedValue:
          (shouldUseStoredFilters &&
            storedCountsFilterFormatted.find(
              (filter) => filter.filterName === 'periodicitiesId'
            )?.textValue) ||
          storedCountsFilterFormatted.find(
            (filter) => filter.filterName === 'periodicitiesId'
          )?.textValue || periodQueryParam
      },
      {
        label: 'Local de estoque',
        filterName: 'storageLocationId',
        placeHolder: 'Selecione',
        size: 'medium',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/storageLocations`,
        key: 'storageLocations',
        optionReference: 'description',
        options: [],
        storedValue:
          (shouldUseStoredFilters &&
            storedCountsFilterFormatted.find(
              (filter) => filter.filterName === 'storageLocationId'
            )?.textValue) ||
          storedCountsFilterFormatted.find(
            (filter) => filter.filterName === 'storageLocationId'
          )?.textValue

      },
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium',
        searchByTermMode: true,
        storedValue:
          (shouldUseStoredFilters &&
            storedCountsFilterFormatted.find(
              (filter) => filter.filterName === 'productDescription'
            )?.productDescription) ||
          storedCountsFilterFormatted.find(
            (filter) => filter.filterName === 'productId'
          )?.textValue
      },
      {
        label: 'Diferença',
        filterName: 'difference',
        placeHolder: 'Selecione...',
        size: 'medium_plus',
        type: 'autoComplete',
        key: 'difference',
        defaultValue: diffFilterDefaultValue,
        isSelect: true,
        allowClear: true,
        storedValue:
          shouldUseStoredFilters &&
          storedCountsFilterFormatted.find(
            (filter) => filter.filterName === 'difference'
          )?.textValue,
        options: [
          { title: 'Todas', value: '' },
          { title: 'Falta', value: 'lack' },
          { title: 'Sobra', value: 'leftover' },
          { title: 'Sem diferença', value: 'noDiff' }
        ]
      },
      {
        label: 'Usuário',
        // defaultValue: DEFAULT_FILTERS.find((f) => f.param === 'user').value,
        filterName: 'user',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium',
        storedValue:
          shouldUseStoredFilters &&
          storedCountsFilterFormatted.find(
            (filter) => filter.filterName === 'user'
          )?.user
      }
    ]
  ]);

  const getTotalDiffValue = (params) => {
    Axios.get(
      `${environments.catalog}/inventories/setups/count/total?originId=${paramsID}&${params}`
    )
      .then((response) => {
        setTotalDiffValue(response?.data);
      })
      .catch((error) => {
        setTotalDiffValue(0);
        console.log('error =>', error);
      });
  };

  const getInventoriesData = useCallback(
    async (currentStoredFilter = []) => {
      setLoading(true);
      const currentFilter = currentStoredFilter?.length
        ? currentStoredFilter
        : storedCountsFilterFormatted;

      let params = [];

      if (shouldUseStoredFilters) {
        const parsedFilters = currentFilter
          ?.map((filter) => {
            const key = Object.keys(filter)[0];
            const isFalse = filter[key] === false;

            if (filter[key]) {
              return `${key}=${filter[key]}&`;
            }
            return isFalse ? `${key}=false` : null;
          })
          .join('');

        params = parsedFilters;
      } else {
        params = filtersChanged?.map((filter) => filter?.urlParam).join('&');
      }

      axiosInstanceCatalog
        .get(
          `${environments.catalog}/inventories/setups/count?originId=${paramsID}&${params}`
        )
        .then((response) => {
          setInventories(
            typeof response?.data === 'string' ? [] : response?.data || []
          );

          getTotalDiffValue(params);
          setLoading(false);
        })
        .catch((error) => {
          setInventories([]);
          setLoading(false);
          console.log(error);
        });
    },
    [filtersChanged, page, paramsID]
  );

  const onCloseProductDialog = (options) => {
    if (options?.refreshData) {
      getInventoriesData();
    }
    setSelectedCount();
    setModalStates({ ...modalStates, productDialog: false });
  };

  const handleProductDialog = async (
    count,
    periodicity,
    storageLocation,
    disableOpenDialog = false
  ) => {
    const response = await Axios.get(
      `${environments.catalog}/inventories/setups/count/products?originId=${paramsID}&countId=${count?.id}`
    );
    const products = await response.data;

    if (products.length) {
      const uniqueProducts = [];
      products.map((pro) =>
        !uniqueProducts.find((x) => x.productId === pro.productId)
          ? uniqueProducts.push(pro)
          : null
      );
      setProductDialogList(uniqueProducts);
      setProductDialogTitle({ periodicity, storageLocation });
      setSelectedCount(count);
      setSelectedProduct('');

      !disableOpenDialog &&
        setModalStates((prevState) => ({
          ...prevState,
          productDialog: true
        }));
    }
  };

  const getSalesProcessInfos = useCallback(() => {
    Axios.get(
      `${environments.catalog}/queue/sales/health?restaurantId=${userInfo.companiesActive.id}`
    )
      .then((response) => {
        setSalesProcessInfos(response?.data || {});
      })
      .catch((error) => {
        setSalesProcessInfos({});
        console.log(error);
      });
  }, [userInfo.companiesActive.id]);

  const handleAddProduct = useCallback(async () => {
    try {
      await Axios({
        method: 'post',
        url: `${environments.catalog}/products/${selectedProduct?.id}/setup/${selectedCount?.setup?.id}`,
        data: {
          restaurantId: paramsID,
          date: selectedCount?.date,
          user: userInfo.user,
          countId: selectedCount?.id
        }
      });
      setToastOpen(true);
      setToastProps({
        message: 'Produto adicionado com sucesso',
        severity: 'success'
      });
      setSelectedProduct(null);
      setModalStates({
        ...modalStates,
        confirmModal: false
      });
      handleProductDialog(
        selectedCount,
        productDialogTitle?.periodicity,
        productDialogTitle?.storageLocation,
        true
      );
      getInventoriesData();
    } catch (error) {
      const isInternalProductError = error?.response?.data?.message;

      setToastOpen(true);
      setToastProps({
        message: isInternalProductError,
        severity: 'error'
      });
      setStatusCode(error?.response?.status)
    }
  }, [
    selectedCount?.date,
    selectedCount?.id,
    paramsID,
    selectedProduct?.id,
    setSelectedProduct,
    setToastOpen,
    setToastProps,
    selectedCount?.setup?.id,
    userInfo.user
  ]);

  const handleRemoveProduct = useCallback(
    async (productId) => {
      const formattedDate = moment(selectedCount?.date).format('YYYY-MM-DD');

      try {
        await Axios({
          method: 'put',
          url: `${environments.catalog}/products/${productId}/setup/${selectedCount?.setup?.id}/countDate/${formattedDate}?user=${userInfo.user}`
        });
        setToastOpen(true);
        setToastProps({
          message: 'Produto removido com sucesso',
          severity: 'success'
        });
        setSelectedProduct(null);
        handleProductDialog(
          selectedCount,
          productDialogTitle?.periodicity,
          productDialogTitle?.storageLocation,
          true
        );
        getInventoriesData();
      } catch (error) {
        setToastOpen(true);
        setToastProps({
          message: error?.response?.data?.message,
          severity: 'error'
        });
        setStatusCode(error?.response?.status)
      }
    },
    [
      selectedCount?.date,
      selectedCount?.id,
      paramsID,
      selectedProduct?.id,
      setSelectedProduct,
      setToastOpen,
      setToastProps,
      selectedCount?.setup?.id,
      userInfo.user
    ]
  );

  const onConfirmConfirmationDialogButton = () => {
    if (confirmModalInfos?.name === 'addProduct') {
      handleAddProduct();
    }

    if (confirmModalInfos?.name === 'deleteProduct') {
      handleRemoveProduct(confirmModalInfos?.productId);
    }

    setConfirmModalInfos({
      name: '',
      contentText: '',
      productId: ''
    });

    setModalStates({
      ...modalStates,
      confirmModal: false
    });
  };

  const handleRedirectToCounts = (count, item, countGroupInfo, period, currentActiveTab) => {
    const countGroupObj = countGroupInfo?.counts?.periodicities?.find(
      (obj) =>
        obj?.periodicity?.id === countGroupInfo?.period?.periodicity?.id
    ) ||
    item?.periodicities?.find(
      (el) => el?.periodicity?.id === period?.periodicity?.id
    )
    if (count.setupId) {
      setCountId(
        count.id,
        'COUNTS',
        count.date,
        count.assortmentInfo,
        period?.periodicity?.subtractDays
      );
      history.push({
        pathname: `/inventario/contagem-diaria/${paramsID}/${count?.id}?countDate=${count?.date}&countAssortDate=${count?.assortmentInfo?.date}&countAssortUser=${count?.assortmentInfo?.user}`,
        state: {
          isCountGroup: countGroupInfo?.state || countGroupInfo,
          currentActiveTab,
          countGroup: countGroupObj
        }
      });
    } else {
      setCountId(
        count.id,
        'COUNTS',
        count.date,
        null,
        period?.periodicity?.subtractDays
      );
      history.push({
        pathname: `/inventario/contagem-avulsa/${paramsID}`,
        state: {
          isCountGroup: countGroupInfo?.state || countGroupInfo,
          currentActiveTab,
          countGroup: countGroupObj
        }
      });
    }
  };

  const handlePlayCounts = (
    count,
    status,
    item,
    countGroup = 'false',
    period,
    currentActiveTab
  ) => {
    let dayAgoCounts = [];
    const itemDate = item.date === format(new Date(), 'yyyy-MM-dd');
    const hasUnfinishedCount =
      dayAgoCounts.filter((count) => count.status !== 'FINISHED').length > 0;

    const dayAgoInventory = inventories.filter(
      (inventory) =>
        inventory.date ===
        format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd')
    )[0];

    if (dayAgoInventory) {
      dayAgoCounts = dayAgoInventory.periodicities
        .reduce((acc, periodicity) => [...acc, periodicity.counts], [])
        .flat();
    }

    if (itemDate && hasUnfinishedCount) {
      setSelectedCount(count);
      setCountsPendingModal(true);
      setCountGroup({ state: countGroup, counts: item, period });
    } else {
      handleRedirectToCounts(count, item, countGroup, period, currentActiveTab);
    }
  };

  const handleClose = (confirmClose = false) => {
    if (hasChangeForm && !confirmClose) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setOpenSetupModal(false);
      setAlertErrItens(false);
      setSuccessMessage(false);
      setModalStates({
        modalOutOfCountProducts: false,
        modalProductSetups: false,
        modalEmpty: false
      });
      setHasChangeForm(false);
    }
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const handleStartCounts = (id) => {
    setLoading(true);

    const formattedDate = format(date || new Date(), 'yyyy-MM-dd');

    Axios.get(
      `${environments.catalog}/inventories/setup/${id}?originId=${paramsID}&user=${userInfo.user}&countDate=${formattedDate}`
    )
      .then((response) => {
        setBlockButton(false)
        setCountId(response.data.id);
        setToastOpen(true);
        setToastProps({
          message: 'Contagem criada com sucesso',
          severity: 'success'
        });
        getInventoriesData();
        handleClose();
      })
      .catch((error) => {
        setBlockButton(false)
        setLoading(false);
        const errorMessage = error?.response?.data?.message;
        setToastOpen(true);
        setToastProps({
          message:
            errorMessage
            || 'Não foi possível criar as contagens',
          severity: 'error'
        });
        console.log(error);
      });
  };

  const handleStartPeriodicityCounts = (periodicityId, selectedDate) => {
    setLoading(true);
    const body = {
      restaurantId: paramsID,
      date: selectedDate
    };

    Axios.post(
      `${environments.catalog}/inventories/counts/open/${periodicityId}`,
      body
    )
      .then(() => {
        setToastOpen(true);
        setBlockButton(false)
        setToastProps({
          message:
            'Contagens sendo criadas... por favor, atualize a tela para visualizá-las',
          severity: 'success'
        });
        getInventoriesData();
        handleClose();
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        setBlockButton(false)
        setLoading(false);
        setToastOpen(true);
        setToastProps({
          message:
            errorMessage === 'Counts already open for this date'
              ? 'Contagens já existentes para este dia'
              : 'Não foi possível criar as contagens',
          severity: 'error'
        });
        setStatusCode(error?.response?.status)
      });
  };

  const onDismiss = () => {
    setAlertErrItens(false);
  };

  const clearFilterValues = (param) => {
    setDifferenceQueryParam(param === 'difference' ? '' : differenceQueryParam);
    setInitialDateFilterDefaultValue(
      param === 'initialDate' ? '' : initialDateFilterDefaultValue
    );
    setEndDateFilterDefaultValue(
      param === 'finalDate' ? '' : endDateFilterDefaultValue
    );
    setProductQueryParam(param === 'productId' ? '' : productQueryParam);
    setPeriodQueryParam(param === 'periodicitiesId' ? '' : periodQueryParam);

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((item) => {
          if (item?.filterName === param) {
            return {
              ...item,
              storedValue: ''
            };
          }

          return item;
        });
      }

      return filterLine;
    });

    setFilterFields(FILTER_FIELDS);
  };

  const handleFilter = useCallback(
    (param, value, filter, paramSecond) => {
      const hasFilterWithFirstName = filtersChanged?.filter(
        (el) => el?.param === param
      )?.length;
      const hasFilterWithSecondName = filtersChanged?.filter(
        (el) => el?.param === paramSecond
      )?.length;

      let formattedFilters = hasFilterWithSecondName
        ? filtersChanged?.filter((el) => el?.param !== paramSecond)
        : filtersChanged;

      if (!value) {
        clearFilterValues(param);
        dispatch(clearFilter({
          param: hasFilterWithSecondName ? paramSecond : param, 
          value, 
          textValue: filter?.title
        }));

        if (hasFilterWithFirstName || hasFilterWithSecondName) {
          formattedFilters = filtersChanged?.filter(
            (el) => el?.param !== paramSecond && el?.param !== param
          );
        }
      }

      const urlParams = new URLSearchParams(queryParams);

      if (urlParams.has(param)) {
        urlParams.delete(param);
        history.replace({
          search: urlParams.toString()
        });
      }

      dispatch(
        updateFilter({
          param,
          value,
          textValue: filter?.title
        })
      );

      handleFilterOptions(
        param,
        value,
        formattedFilters,
        setFiltersChanged,
        null,
        false
      );
    },
    [dispatch, filtersChanged]
  );

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    let [startDate, endDate] = '';

    if (
      paramsAndValues.map((el) => el.includes('startDate')).some((el) => el)
    ) {
      startDate = paramsAndValues.find((el) => el.includes('startDate'));

      dispatch(
        updateFilter({
          param: startDate[0],
          value: startDate[1],
          textValue: startDate[0]
        })
      );
    }

    if (paramsAndValues.map((el) => el.includes('endDate')).some((el) => el)) {
      endDate = paramsAndValues.find((el) => el.includes('endDate'));

      dispatch(
        updateFilter({
          param: endDate[0],
          value: endDate[1],
          textValue: endDate[0]
        })
      );
    }

    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      paramsID,
      filtersChanged,
      page
    );
  };

  const totalDiffStyle = (value) => {
    let bg = '';
    let color = '';

    if (value === 0) {
      bg = '#E6E7F0';
      color = '#0E1337';
    } else if (value > 0) {
      bg = '#FFF2CC';
      color = '#FFBF3E';
    } else {
      bg = '#F7D4D7';
      color = '#F53D4C';
    }

    return {
      background: bg,
      color
    };
  };

  const renderTotalDiff = () => (
    <div className="dFlex alignCenter totalDiffBox">
      <span className="fontSizeDefault totalDiffBoxLabel bold">
        Total de Diferenças:
      </span>

      <div
        className="dFlex justifyEnd alignCenter totalDiffBoxValue"
        style={{
          background: totalDiffStyle(totalDiffValue?.sumDifferenceTotal || 0)
            .background
        }}
      >
        <span
          style={{
            color: totalDiffStyle(totalDiffValue?.sumDifferenceTotal || 0).color
          }}
          className="fontSizeDefault bold"
        >
          R${' '}
          {(totalDiffValue?.sumDifferenceTotal || 0)?.toLocaleString('pt-br', {
            minimumFractionDigits: 3
          })}
        </span>
      </div>
    </div>
  );

  const renderLeftOverAndLack = () => (
    <div className="dFlex leftOverAndLackBox">
      <div
        className="dFlex alignCenter leftOverBox"
        style={{
          marginRight: 12
        }}
      >
        <span className="fontSizeDefault bold" style={{ padding: '5px 6px' }}>
          Sobras:
        </span>

        <div
          className="dFlex justifyEnd alignCenter"
          style={{
            borderRadius: 50,
            boxShadow: '0px 4px 4px 0px #00000040',
            background:
              totalDiffValue?.sumPositiveTotal <= 0 ? '#E6E7F0' : '#FFF2CC'
          }}
        >
          <span
            style={{
              padding: '5px 6px',
              color:
                totalDiffValue?.sumPositiveTotal <= 0 ? '#0E1337' : '#F2B90D'
            }}
            className="fontSizeDefault"
          >
            R${' '}
            {totalDiffValue?.sumPositiveTotal?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })}
          </span>
        </div>
      </div>

      <div className="dFlex alignCenter lackBox">
        <span className="fontSizeDefault bold" style={{ padding: '5px 6px' }}>
          Faltas:
        </span>

        <div
          className="dFlex justifyEnd alignCenter"
          style={{
            borderRadius: 50,
            boxShadow: '0px 4px 4px 0px #00000040',
            background:
              totalDiffValue?.sumNegativeTotal >= 0 ? '#E6E7F0' : '#F7D4D7'
          }}
        >
          <span
            style={{
              padding: '5px 6px',
              color:
                totalDiffValue?.sumNegativeTotal >= 0 ? '#0E1337' : '#F53D4C'
            }}
            className="fontSizeDefault"
          >
            R$
            {totalDiffValue?.sumNegativeTotal?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })}
          </span>
        </div>
      </div>
    </div>
  );

  const productSetupsButton = () => (
    <Tooltip title="Setups de Produto" aria-label="filters">
      <Link
        onClick={() =>
          setModalStates({ ...modalStates, modalProductSetups: true })
        }
      >
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img
            style={{ width: '25px', marginRight: '23px' }}
            src={ProductSetupIcon}
            alt="Setup de Produtos"
          />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const newSetupButton = () => (
    <Tooltip title="Novo Setup" aria-label="filters">
      <Link onClick={() => setOpenSetupModal(true)}>
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img
            style={{
              marginRight: '23px',
              color: '#FFF',
              padding: '5px',
              background: '#5062F0',
              width: '25px',
              height: '25px',
              borderRadius: '6px'
            }}
            src={SetupIcon}
            alt="Pedidos"
          />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const addCountButton = () => (
    <Tooltip title="Contar item" aria-label="filters">
      <Link to={`/inventario/contagem-avulsa/${paramsID}`}>
        <IconButton
          className="p0"
          onClick={() => setCountId(null)}
          edge="start"
          color="inherit"
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="Contagens" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const settingsMobile = [
    {
      description: 'Contar Adicional de Itens',
      action: () => {
        setOpenAdditionalItens(true);
      }
    }
  ];

  const onClearFilters = () => {
    const TODAY = new Date();
    dispatch(clearFilter());

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((filter) => {
          if (filter.type === 'dateRangeField') {
            return {
              type: 'dateRangeField',
              size: 'small',
              filterNameFirst: 'startDate',
              filterNameSecond: 'endDate',
              initialDate: actualDateLessSeven,
              endDate: TODAY
            };
          }

          return {
            ...filter,
            storedValue: ''
          };
        });
      }

      return filterLine;
    });

    setFilters(DEFAULT_FILTERS);
    setFiltersChanged(DEFAULT_FILTERS);
    setFilterFields(FILTER_FIELDS);
  };

  useEffect(() => {
    if (
      !storedCountsFilter?.find((el) => el?.filterName?.includes('Id'))
        ?.productId
    ) {
      getInventoriesData();
      getSalesProcessInfos();
    }
  }, [
    page,
    userInfo,
    filtersChanged,
    storedCountsFilter?.find((el) => el?.filterName?.includes('Id')?.productId)
  ]);

  useEffect(() => {
    if (
      storedCountsFilter?.find((el) => el?.filterName?.includes('Id'))
        ?.productId
    ) {
      getInventoriesData(storedCountsFilterFormatted);
      getSalesProcessInfos();
    }
  }, [
    filtersChanged, 
    storedCountsFilter?.find((el) => el?.filterName?.includes('Id'))?.productId
  ]);

  useEffect(() => {
    localStorage.removeItem('restaurantId');

    const params = filtersChanged
      ?.filter((filter) => filter)
      ?.map((filter) => filter?.urlParam)
      .join('&');

    Axios.get(
      `${environments.catalog}/inventories/setup?originId=${paramsID}&${params}`
    )
      .then((response) => {
        setInventoriesModal(response?.data?.content || []);
      })
      .catch((error) => {
        setInventoriesModal([]);
        console.log(error);
      });
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      const response = await Axios.get(
        `${environments.catalog}/products/inventories/setups/countProductsWithoutSetups?originId=${paramsID}`
      );
      const products = await response.data;
      setOutOfCountProducts(products || 0);
    })();
  }, [userInfo, modalStates.modalOutOfCountProducts]);

  // Contagem
  useEffect(() => {
    (async () => {
      const response = await Axios.get(
        `${environments.catalog}/periodicities?page=1&size=100&originId=${paramsID}`
      );
      const items = await response.data;
      if (!items || !items?.content?.length) {
        setPeriodicities([]);
        return;
      }
      setPeriodicities(items.content);
    })();
  }, [userInfo]);

  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Contagens" />

      <Container>
        <PageTitleAndFilter
          title={
            backButtonConfig?.pageTitle
              ? backButtonConfig?.pageTitle
              : 'Contagens'
          }
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          supplementarybutton={productSetupsButton}
          additionalbutton={addCountButton}
          extrabutton={newSetupButton}
          getFilterhandle={getFilterhandle}
          // settings
          backButton={backButtonConfig}
          // settingsMobile={settingsMobile}
          onClear={onClearFilters}
          status={
            salesProcessInfos?.statusProcessing && (
              <Tooltip
                placement="top"
                title={
                  <p className="mb0" style={{ padding: '4px 2px' }}>
                    Processamento de Vendas:{' '}
                    {salesProcessInfos?.statusProcessing === 'ACTIVE'
                      ? 'Ativo'
                      : 'Inativo'}
                  </p>
                }
              >
                <img
                  style={{ width: '25px', marginRight: '23px' }}
                  src={
                    salesProcessInfos?.statusProcessing === 'ACTIVE'
                      ? CheckedIcon
                      : AlertIcon
                  }
                  alt="Sales Process Status"
                />
              </Tooltip>
            )
          }
        />

        <div className="dFlex alignCenter countsSubHeader justifyBetween">
          {outOfCountProducts ? (
            <div className="productsOutOfCountBox" style={{ display: 'flex' }}>
              <p>
                Você tem{' '}
                {outOfCountProducts && typeof outOfCountProducts === 'number'
                  ? outOfCountProducts
                  : 0}{' '}
                {outOfCountProducts?.length === 1 ? 'produto' : 'produtos'} fora
                da contagem.
              </p>

              <span
                onClick={() =>
                  setModalStates({
                    ...modalStates,
                    modalOutOfCountProducts: true
                  })
                }
              >
                Adicione eles aqui.
              </span>
            </div>
          ) : null}

          <div
            className={`diffInfosContainer ${!outOfCountProducts && 'w100'}`}
          >
            {renderTotalDiff()}

            {renderLeftOverAndLack()}
          </div>
        </div>

        {/* <CountsMobile
          countData={inventories}
          userInfo={userInfo}
          setBackButtonConfig={setBackButtonConfig}
          inventories={inventories}
        /> */}

        <DesktopTableWrapper>
          <table
            className="desktop countsList"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <TrTablet>
              <tr>
                <th width="6%" className="subtitle pl6 pr6">
                  DATA
                </th>

                <th
                  width="26%"
                  className="subtitle pl6 pr6"
                >
                  CONTAGEM
                </th>

                <th
                  width="11%"
                  className="subtitle pl6 pr6"
                  style={{ borderLeft: 'solid 1px #D0D3E2' }}
                >
                  FREQUÊNCIA
                </th>

                <th
                  width="33%"
                  className="subtitle pl6 pr6"
                  style={{ borderLeft: 'solid 1px #D0D3E2' }}
                >
                  LOCAL DO ESTOQUE
                </th>

                <th
                  width="4%"
                  className="subtitle pl6 pr6"
                >
                  <img
                    style={{ width: '30px' }}
                    src={ProdIcon}
                    alt="ProdIcon"
                  />
                </th>

                <th
                  width="10%"
                  className="subtitle center pl6 pr6"
                  style={{ borderLeft: 'solid 1px #D0D3E2' }}
                >
                  STATUS
                </th>

                <th width="4%" className="subtitle textAlignCenter pl6 pr6">
                  INFO
                </th>

                {/* <th width="4%" className="pl6 pr6" /> */}
              </tr>
            </TrTablet>

            <TrDesk>
              <tr className="headerTitle">
                <th width="5%" className="subtitle">
                  DATA
                </th>

                <th
                  width="19%"
                  className="subtitle pl6"
                >
                  Contagem
                </th>

                <th
                  width="3%"
                  className="subtitle"
                >
                  &nbsp;
                </th>

                <th
                  width="8%"
                  className="subtitle"
                  style={{ paddingRight: '5px' }}
                >
                  FREQUÊNCIA
                </th>

                {/* <Tooltip title="Indicador de desvio" aria-label="filters">
                  <th
                    width="4%"
                    className="subtitle"
                    style={{textAlign: 'right', paddingRight: '5px'}}
                  >
                    DESVIO %
                  </th>
                </Tooltip> */}

                <th
                  width="22%"
                  className="subtitle pl6"
                >
                  LOCAL DE ESTOQUE
                </th>
                
                <th
                  width="3%"
                  className="subtitle"
                  style={{ padding: '0 12px 0 6px' }}
                >
                  <img
                    style={{ width: '30px' }}
                    src={ProdIcon}
                    alt="ProdIcon"
                  />
                </th>

                {/* <Tooltip title="Indicador de desvio"  aria-label="filters">
                  <th
                    width="4%"
                    className="subtitle"
                    style={{textAlign: 'right', paddingRight: '5px'}}
                  >
                    DESVIO %
                  </th>
                </Tooltip> */}

                <th
                  width="6%"
                  className="subtitle"
                  style={{ padding: '0 6px' }}
                >
                  STATUS
                </th>
                
                <th width="4%" className="subtitle" />

                <th
                  width="9%"
                  className="subtitle"
                  style={{ padding: '0 6px' }}
                >
                  ÚLTIMA CONTAGEM
                </th>

                <th
                  width="10%"
                  className="subtitle"
                  style={{ padding: '0 6px' }}
                >
                  USUÁRIO
                </th>

                <th width="2%" />
                <th width="2%" />
                <th width="2%" />
              </tr>
            </TrDesk>

            <tbody>
              {loading && (
                <tr>
                  <th
                    colSpan="13"
                    style={{ margin: '10px', textAlign: 'center' }}
                  >
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              <CountTable
                countData={inventories}
                handlePlayCounts={handlePlayCounts}
                refreshCount={getInventoriesData}
                userInfo={userInfo}
                removePermission={['restaurants', 'managers', 'producers', 'stockists']}
                handleProductDialog={handleProductDialog}
                setToastOpen={setToastOpen}
                setToastProps={setToastProps}
              />
            </tbody>
          </table>
        </DesktopTableWrapper>

        <CommonAlert
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleCloseToast}
          severity={toastProps?.severity}
          indexMessage={toastProps?.message}
          messagePersonal={toastProps?.message}
          statusCode={statusCode}
        />

      </Container>

      {openAdditionalItens && (
        <Redirect to={`/inventario/contagem-adicional-de-item/${paramsID}`} />
      )}

      {modalStates.modalOutOfCountProducts && (
        <Suspense fallback={<span />}>
          <OutOfCountProductsDialog
            openModal={modalStates}
            handleClose={handleClose}
            userInfo={userInfo}
            periodicities={periodicities}
          />
        </Suspense>
      )}

      {modalStates.modalProductSetups && (
        <Suspense fallback={<span />}>
          <ProductSetupsDialog
            openModal={modalStates}
            handleClose={handleClose}
            userInfo={userInfo}
            periodicities={periodicities}
            hasChangeForm={hasChangeForm}
            setHasChangeForm={setHasChangeForm}
            isOpenConfirm={isOpen}
            setIsOpenConfirm={setIsOpen}
          />
        </Suspense>
      )}

      {modalStates?.productDialog && (
        <Suspense fallback={<span />}>
          <DialogCount
            openState={modalStates.productDialog}
            productList={productDialogList}
            onCloseProductDialog={() => onCloseProductDialog()}
            productDialogTitle={productDialogTitle}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            modalStates={modalStates}
            setModalStates={setModalStates}
            setConfirmModalInfos={setConfirmModalInfos}
          />
        </Suspense>
      )}

      {modalStates?.confirmModal && (
        <ConfirmModal
          open={modalStates?.confirmModal}
          content={
            <Typography className="bold">
              {confirmModalInfos?.contentText}
            </Typography>
          }
          onClose={() => {
            setModalStates({
              ...modalStates,
              confirmModal: false
            });
          }}
          cancelText="Não"
          onConfirm={onConfirmConfirmationDialogButton}
          confirmText="Sim"
        />
      )}

      {openSetupModal && (
        <Dialog
          open={openSetupModal}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          key="dialog_1k"
          className="setupProductModal defaultModal"
        >
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>Setup</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Grid container>
              {alertErrItens ? (
                <Alert
                  className="alertError"
                  color="danger"
                  isOpen={alertErrItens}
                  toggle={onDismiss}
                >
                  {getAlert || 'Erro ao criar novo setup'}
                </Alert>
              ) : null}

              <Grid item xs={4} className="pt20 pb20">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      size="small"
                      id="time-picker"
                      label="Data Inicial"
                      format="dd/MM/yyyy"
                      ampm={false}
                      clearable
                      fullWidth
                      inputVariant="outlined"
                      value={date}
                      className="dateSmall"
                      onChange={(date) => {
                        setDate(date);
                      }}
                      KeyboardButtonProps={{
                        size: 'small',
                        'aria-label': 'change time'
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <table
                className="desktop"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th width="30" className="subtitle pt0 pb0 pl10 pr10">
                      &nbsp;
                    </th>
                    <th className="subtitle pt0 pb0 pl0">CONTAGEM</th>
                    <th className="subtitle pt0 pb0 pl30">LOCAL DE ESTOQUE</th>
                    <th className="subtitle pt0 pb0">PRÓXIMA</th>
                  </tr>
                </thead>

                <tbody>
                  {inventoriesModal.length ? (
                    inventoriesModal.map((inventory, inventoryIndex) =>
                      inventory?.setups?.map((setup, index) => {
                        if (setup.status) {
                          let status = '';

                          switch (setup.status.toUpperCase()) {
                            case 'WAITING':
                              status = 'Não iniciada';
                              break;
                            case 'IN PROGRESS':
                              status = 'Parcial';
                              break;
                            case 'FINISHED':
                              if (
                                isToday(new Date(setup.nextCount)) ||
                                Moment().isAfter(
                                  new Date(setup.nextCount),
                                  'day'
                                )
                              ) {
                                setup.status = 'WAITING';
                                status = 'Finalizada';
                              } else {
                                status = 'Finalizada';
                              }
                              break;
                            default:
                              status = setup.status;
                              break;
                          }
                        }

                        let nextDate = {
                          color: '',
                          label: '',
                          date: ''
                        };

                        const days =
                          differenceInDays(
                            new Date(setup.nextCount),
                            new Date()
                          ) + 1;
                        const dayString = `${days} dias`;

                        if (isToday(new Date(setup.nextCount))) {
                          if (setup.status !== 'WAITING') {
                            nextDate = {
                              color: 'success',
                              label: 'Hoje',
                              date: Moment().format('YYYY-MM-DD')
                            };
                          } else {
                            nextDate = {
                              color: 'danger',
                              label: 'Hoje',
                              date: Moment().format('YYYY-MM-DD')
                            };
                          }
                        } else if (isTomorrow(new Date(setup.nextCount))) {
                          nextDate = {
                            color: 'warning',
                            label: 'Amanhã',
                            date: Moment().add(1, 'days').format('YYYY-MM-DD')
                          };
                        } else if (days > 1) {
                          nextDate = {
                            color: 'success',
                            label: dayString,
                            date: Moment()
                              .add(days, 'days')
                              .format('YYYY-MM-DD')
                          };
                        } else if (
                          Moment().isAfter(new Date(setup.nextCount), 'day')
                        ) {
                          nextDate = {
                            color: 'danger',
                            label: 'Atrasado',
                            date: Moment(setup.nextCount).format('YYYY-MM-DD')
                          };
                        }

                        const renderNextDate = () => (
                          <ButtonChip
                            style={{ marginTop: 4 }}
                            label={nextDate?.label}
                            color={nextDate?.color}
                            background={nextDate?.color}
                          />
                        );

                        const currentStorageLocation =
                          inventory?.storageLocations?.find(
                            (_, storageIndex) => storageIndex === index
                          );

                        const periodicity =
                          periodicities.find(
                            (period) =>
                              period?.id === inventory?.periodicity?.id
                          ) || '';

                        const isLastSetup =
                          index === inventory?.setups?.length - 1;

                        const borderSeparator = (onlyLast) => {
                          if (!onlyLast) {
                            return isLastSetup
                              ? '1px solid rgb(49, 51, 71)'
                              : '1px solid rgb(229, 229, 229)';
                          }

                          return isLastSetup && '1px solid rgb(49, 51, 71)';
                        };

                        return (
                          <tr key={setup.id} valign="top">
                            <td
                              className="pt0 pb0 pl10 pr10"
                              style={{
                                borderBottom: borderSeparator(true)
                              }}
                            >
                              {index === 0 && (
                                <IconButton
                                  aria-label="playIcon"
                                  onClick={() => {
                                    setBlockButton(true)

                                    if (setup.items.length === 0) {
                                      setAlertErr(true);
                                    } else {
                                      handleStartPeriodicityCounts(
                                        inventory?.periodicity?.id,
                                        date
                                      );
                                    }
                                  }}
                                  style={{
                                    color: '#000000',
                                    padding: 0,
                                    outline: 0,
                                    marginTop: 4
                                  }}
                                  disabled={blockButton}
                                  className='disabledButton'
                                >
                                  <PlayCircleOutline className="playIcon black" />
                                </IconButton>
                              )}
                            </td>

                            <td
                              className="description pt0 pb0 pl0"
                              style={{
                                borderBottom: borderSeparator(true)
                              }}
                            >
                              {index === 0 &&
                                ValidationLength(
                                  periodicity?.description?.toString() || '',
                                  20
                                )}
                            </td>

                            <td
                              className="description pb0 pl0"
                              style={{
                                borderBottom: borderSeparator()
                              }}
                            >
                              <div className="dFlex alignCenter">
                                <IconButton
                                  aria-label="playIcon"
                                  className='disabledButton'
                                  disabled={blockButton}
                                  onClick={() => {
                                    setBlockButton(true)

                                    if (setup.items.length === 0) {
                                      setAlertErr(true);
                                    } else {
                                      handleStartCounts(setup.id);
                                    }
                                  }}
                                  style={{
                                    color: '#000000',
                                    padding: 0,
                                    outline: 0,
                                    marginRight: 10
                                  }}
                                >
                                  <PlayCircleOutline className="playIcon black" />
                                </IconButton>

                                {currentStorageLocation?.description}
                              </div>
                            </td>

                            <td
                              className="description pt0 pb0"
                              style={{
                                borderBottom: borderSeparator()
                              }}
                            >
                              {renderNextDate()}
                            </td>
                          </tr>
                        );
                      })
                    )
                  ) : inventoriesModal?.length === 0 ? (
                    <tr>
                      <th
                        colSpan="18"
                        style={{ margin: '10px', textAlign: 'center' }}
                      >
                        Não existe nenhuma contagem
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <th colSpan="17" sytle={{ margin: '10px' }}>
                        <LinearProgress variant="query" />
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
          </DialogContent>

          <CommonAlert
            open={successMessage}
            autoHideDuration={60}
            onClose={handleClose}
            severity="success"
            messagePersonal="Cadastro realizado com sucesso!"
          />
        </Dialog>
      )}

      {/* Modal Counts Pending Alert */}
      <Dialog
        open={countsPendingModal}
        onClose={() => {
          setCountsPendingModal(false);
          setCountGroup({ state: false, counts: {}, period: {} });
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="setupProductModal w-auto"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
        >
          <Typography style={{ margin: '12px' }}>
            Existem contagens pendentes do dia anterior. Deseja continuar?
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setCountsPendingModal(false);
              setCountGroup({ state: false, counts: {}, period: {} });
            }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent pb20">
          <Grid container>
            <Grid item xs={12} sm={12} className="mt20 dFlex justifyCenter">
              <Button
                className="defaultButton backButton"
                onClick={() => {
                  setCountsPendingModal(false);
                  setCountGroup({ state: false, counts: {}, period: {} });
                }}
                design="outlined"
                label="Não"
              />

              <Button
                className="defaultButton submitButton"
                onClick={() => {
                  handleRedirectToCounts(selectedCount, null, countGroup);
                }}
                design="contained"
                style={{ marginLeft: '20px' }}
                label="Sim"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  countInfo: state.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CountActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CountsList);
