const INITIAL = {}

export default function ticketsInfo(state = INITIAL, action) {
  switch (action.type) {
    case "SET_TICKETS_DATA":
      return (
        {
          action: "EDIT",
          data: action.data 
        }
      )
      case "CREATE_TICKETS_DATA":
        return (
          {
            action: "CREATE",
            data: {}
          }
        )
    default:
      return state;
  }
}